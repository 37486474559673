/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { GenericTable, GenericTableRowData } from '../Generic/GenericTable';
import { IServerCommunication, UserResponse, UserRole } from '../../ServerCommunication';

interface UserAdminProps {
  server: IServerCommunication;
  isSectraAdmin: boolean;
}

export const UserAdmin: React.FC<UserAdminProps> = (props) => {
  const [userInput, setUserInput] = useState<string>('');
  const [role, setRole] = useState<UserRole>(UserRole.User);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<boolean[]>([]);
  const [unverifiedUsers, setUnverifiedUsers] = useState<UserResponse[]>([]);
  const [selectedUnverifiedUsers, setSelectedUnverifiedUsers] = useState<boolean[]>([]);

  useEffect(() => {
    async function loadUsers() {
      let newUsers = await props.server.getCurrentSiteUsers();
      setUnverifiedUsers(await props.server.getUnverifiedUsers());
      setUsers(newUsers);
    }
    loadUsers();
  }, []);

  useEffect(() => {
    setSelectedUnverifiedUsers(unverifiedUsers.map(x => false));
  }, [unverifiedUsers]);

  useEffect(() => {
    setSelectedUsers(users.map(x => false));
  }, [users]);

  async function removeUser(user: UserResponse) {
    await props.server.removeUserFromSite(user);
    setUsers([...users.filter(x => x.Guid !== user.Guid)]);
    setUnverifiedUsers(await props.server.getUnverifiedUsers());
  }

  async function setUsersRole() {
    const updatedUsers = await props.server.setUsersRole(users.filter((x, i) => selectedUsers[i]).map(x => x.Guid), role);
    setUsers(updatedUsers);
  }

  async function addUsers(userIds: string[]) {
    const updatedUsers = await props.server.addUsersToSite(userIds);
    setUsers(updatedUsers);
    const updatedUnverified = await props.server.getUnverifiedUsers();
    setUnverifiedUsers(updatedUnverified);
    setUserInput('');
  }

  const userData: GenericTableRowData[] = users.map((x, i) => {
    return {
      values: [x.GivenName ?? ' - ', x.FamilyName ?? ' - ', x.Email ?? ' - ', UserRole[x.Role], x.Guid],
      className: selectedUsers[i] ? 'selected' : '',
      iconId: selectedUsers[i] ? 'icon-Checkmark' : undefined,
    };
  });

  const unverifiedUsersData: GenericTableRowData[] = unverifiedUsers.map((x, i) => {
    return {
      values: [x.GivenName ?? ' - ', x.FamilyName ?? ' - ', x.Email ?? ' - ', x.Guid],
      className: selectedUnverifiedUsers[i] ? 'selected' : '',
      iconId: selectedUnverifiedUsers[i] ? 'icon-Checkmark' : undefined,
    };
  });

  return (
    <>
      <Grid>
        <h2 >
          User Admin
        </h2>
        <p>Any changes made to users will take effect the next time the user logs in.</p>
        <p>When adding users, always double check that the email belongs to the person you intend to add.</p>
        <h3>
          Current users
        </h3>
        <Row className="show-grid">
          <Col xs={12} sm={10}>
            <GenericTable headers={['Given name', 'Family name', 'Email', 'Role', 'User id']} tableRows={userData} onDelete={i => removeUser(users[i])}
              onClick={i => setSelectedUsers(selectedUsers.map((x, ix) => i === ix ? !x : x))} />
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={2}>
            <label htmlFor="user-role"><p>Role</p></label>
          </Col>
          <Col xs={8} sm={6}>
            <ToggleButtonGroup name="roleSelector" className="options" type="radio" value={role} onChange={(x: number) => setRole(x)}>
              <ToggleButton className="btn btn-secondary" value={UserRole.User}>
                {UserRole[UserRole.User]}
              </ToggleButton>
              <ToggleButton className="btn btn-secondary" value={UserRole.Admin}>
                {UserRole[UserRole.Admin]}
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
          <Col xs={2}>
            <button className="btn btn-primary btn-lg"
              onClick={() => setUsersRole()}>Set Role of Selected</button>
          </Col>
        </Row>
        <h3>
          Unverified users
        </h3>
        <p>The following users are registered on this sites configured email domain. You can add these users to this site.</p>
        <Row className="show-grid">
          <Col xs={12} sm={10}>
            <GenericTable headers={['Given name', 'Family name', 'Email', 'User id']} tableRows={unverifiedUsersData} 
              onClick={i => setSelectedUnverifiedUsers(selectedUnverifiedUsers.map((x, ix) => i === ix ? !x : x)) } emptyMessage="There are currently no unverified users" />
          </Col>
        </Row>
        <Row className="show-grid">
          <Col xs={10} sm={8}></Col>
          <Col xs={2}>
            <button className="btn btn-primary btn-lg"
              onClick={() => addUsers(unverifiedUsers.filter((x, i) => selectedUnverifiedUsers[i]).map(x => x.Guid))}>Add Selected</button>
          </Col>
        </Row>
        <h3>Add user by id</h3>
        <p>Add a specific user by providing its id</p>
        <Row className="show-grid">
          <Col xs={2}> 
            <label htmlFor="user-id"><p>User id</p></label>
          </Col>
          <Col xs={8} sm={6}>
            <input id="user-id" className="form-control input-sm" type="text" value={userInput} onChange={e => setUserInput(e.currentTarget.value)} />
          </Col>
          <Col xs={2}>
            <button className="btn btn-primary btn-lg" onClick={() => userInput?.length ? addUsers([userInput]) : null}>Add</button>
          </Col>
        </Row>
      </Grid>
    </>
  );
};