import { Components } from './Components/SrtComponent/Components';
import { applogger } from './applogger';
import { SrComponent } from './Components/BasicTypes';

const components = Object.keys(Components)
  .map(x => Components[x]);

export function getHelpData(): ComponentInfo[] {
  return components
    .sort((a, b) => a.toolboxName.localeCompare(b.toolboxName))
    .map(x => processComponent(x));
}

export interface ComponentInfo {
  name: string;
  toolboxName: string;
  description: string | undefined;
  properties: ComponentInfoProperty[];
}

export type ComponentInfoPropertyType = 'Text' | 'Set' | 'Number' | 'Integer' | 'Boolean' | 'Array' | 'Object';

export interface ComponentInfoProperty {
  name?: string;
  description: string | undefined;
  required: boolean;
  type: ComponentInfoPropertyType[];
  valueOptions: { name: string, description: string | undefined }[];
  arrayItem: ComponentInfoProperty[];
  objProps: ComponentInfoProperty[];
}

export function processComponent(component: SrComponent<any>, isTopLevel: boolean = false): ComponentInfo {
  return {
    name: component.key,
    toolboxName: component.toolboxName,
    description: component.schema.description,
    properties: processObject(isTopLevel ? component.schema : component.schema?.properties?.[component.key]),
  };
}

function processObject(schema: any): ComponentInfoProperty[] {
  if (schema == null || schema.properties == null && schema.items == null) {
    return [];
  }

  const schemaProps = schema.properties || schema.items.properties;
  if (typeof schemaProps !== 'object') {
    applogger.error('Unrecognized schema object', schemaProps);
    return [];
  }

  const props = Object.keys(schemaProps)
    .map(propName => processProperty(schemaProps[propName], propName, schema))
    .filter(x => x != null)
    .sort((a, b) => {
      // sort id on top, then by required
      if (a.name === 'id') {
        return -1;
      } else if (b.name === 'id') {
        return 1;
      }

      return (a.required ? 0 : 1) - (b.required ? 0 : 1);            
    });

  return props;
}

function processProperty(item: any, propName?: string, containerObj?: any): ComponentInfoProperty {
  const type = getTypes(item);
  const enumOptions = getEnumOptions(type, item);
  const arrayOptions = getArrayOptions(type, item.items ?? (Array.isArray(item.anyOf) ? (item.anyOf as any[]).find(x => x.type === 'array')?.items : undefined));
  const isRequired = propName != null ? getIsRequired(containerObj, propName) : false;

  let ret: ComponentInfoProperty = {
    name: propName,
    description: item.description,
    type: getTypes(item),
    valueOptions: enumOptions,
    required: isRequired,
    arrayItem: arrayOptions,
    objProps: type.length === 1 && type[0] === 'Object' ? processObject(item) : [],
  };

  if (propName == null) {
    delete ret.name;
  }

  return ret;
}

function getArrayOptions(type: ComponentInfoPropertyType[], item: any): ComponentInfoProperty[] {
  if (type == null || !Array.isArray(type) || type.length === 0) {
    return [];
  }

  if (type.every(t => t !== 'Array')) {
    return [];
  }

  if (item == null) {
    // array of "any", assume string, number or bool
    return [{
      type: ['Text', 'Number', 'Boolean'],
      required: false,
      description: 'Untyped array',
      arrayItem: [],
      objProps: [],
      valueOptions: [],
    }];
  }

  if (item.anyOf != null && Array.isArray(item.anyOf)) {
    return item.anyOf.map((anyOfItem: any) => processProperty(anyOfItem));
  }

  if (item.$ref != null && String(item.$ref).endsWith('component')) {
    return [{
      type: ['Object'],
      required: false,
      description: 'Any component of the following types: ' + components.map(c => c.key).join(', '),
      arrayItem: [],
      objProps: [],
      valueOptions: [],
    }];
  }

  return [processProperty(item)];
}

function getEnumOptions(type: ComponentInfoPropertyType[], item: any): { name: string, description: string | undefined }[] {
  if (type == null || !Array.isArray(type) || type.length === 0) {
    return [];
  }

  if (type.every(t => t !== 'Set' && t !== 'Boolean')) {
    return [];
  }

  const getEnumItems = (itm: any) => {
    if (itm.enum != null && Array.isArray(itm.enum)) {
      return (itm.enum as string[])
        .map((enumValue, i) => ({ name: enumValue, description: itm.enumDescription?.[i] }));
    }
    const itemType = Array.isArray(itm.type) ? itm.type : (itm.type != null ? [itm.type] : []);
    if (itemType.length <= 2 && itemType.some((t: string) => t === 'boolean')) {
      const itemOpts = ['true', 'false']
        .map((enumValue, i) => ({ name: enumValue, description: itm.enumDescription?.[i] }));

      if (itemType.length === 2 && itemType.some((t: string) => t === 'string')) {
        // we're assuming is a boolean property with the possibility to add a script
        itemOpts.push({ name: '"=expression"', description: 'A boolean expression.' });
      } else {
        const otherTypes = itemType
          .filter((t: string) => t != 'boolean')
          .map((t: string) => getType(t, false));

        if (otherTypes.length > 0) {
          itemOpts.push({ name: 'other', description: 'Other value of type: ' + otherTypes.join(', ') });
        }
      }

      return itemOpts;
    }

    if (itm.type === 'boolean') {
      return ['true', 'false']
        .map((enumValue, i) => ({ name: enumValue, description: itm.enumDescription?.[i] }));
    }

    return [];
  };

  if (item.anyOf != null && Array.isArray(item.anyOf)) {
    const opts: { name: string, description: string | undefined }[] = [];
    item.anyOf.forEach((itm: any) => {
      const tpe = getType(itm.type, itm.enum != null);
      if (tpe === 'Set' || tpe === 'Boolean') {
        opts.push(...getEnumItems(itm));
      } else if (tpe != null) {
        opts.push({ name: tpe, description: itm.description });
      }
    });
    return opts;
  }
    
  return getEnumItems(item);
}

function getTypes(item: any): ComponentInfoPropertyType[] {
  if (item.type != null) {
    return (Array.isArray(item.type) 
      ? item.type.map((t: string) => getType(t, item.enum != null))
      : [getType(item.type, item.enum != null)])
      .filter((t: ComponentInfoPropertyType | null) => t != null);
  }

  if (item.anyOf != null && Array.isArray(item.anyOf)) {
    return item.anyOf
      .map((i: any) => getType(i.type, i.enum != null))
      .filter((t: ComponentInfoPropertyType | null) => t != null);
  }
  return [];
}

function getType(type: string, hasEnumProp: boolean): ComponentInfoPropertyType | null {
  if (type == null) return null;
  switch (type) {
    case 'string': return hasEnumProp ? 'Set' : 'Text';
    case 'integer': return 'Integer';
    case 'number': return 'Number';
    case 'boolean': return 'Boolean';
    case 'object': return 'Object';
    case 'array': return 'Array';
    case 'null': return null;
    default: 
      applogger.warn('Unrecognized type', type);
      return null;   
  }
}

function getIsRequired(containerObj: any, propName: string): boolean {
  if (containerObj == null) {
    return false;
  }

  if (containerObj.required != null && Array.isArray(containerObj.required)) {
    return containerObj.required.includes(propName);
  }
  if (containerObj.anyOf != null && Array.isArray(containerObj.anyOf)) {
    return containerObj.anyOf.some((item: any) => item?.required?.includes(propName));
  }

  return false; 
}