import React from 'react';

// The React options component
export const SvgSymbol: React.FC<{}> = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="icon-ListCollapse" viewBox="0 0 32 32">
      <path d="M16 25.384l-10.694-10.692q-0.879-0.877-0.879-2.12t0.879-2.122q0.877-0.879 2.12-0.879t2.122 0.879l6.452 6.45 6.452-6.45q0.879-0.879 2.121-0.879t2.121 0.879 0.879 2.122-0.879 2.12z" />
    </symbol>
    <symbol id="icon-ListExpand" viewBox="0 0 32 32">
      <path d="M10.452 26.692q-0.879-0.879-0.879-2.121t0.877-2.123l6.45-6.448-6.448-6.45q-0.879-0.879-0.879-2.122t0.879-2.12q0.879-0.879 2.122-0.879t2.12 0.879l10.69 10.692-10.688 10.692q-0.878 0.878-2.122 0.878t-2.122-0.878z" />
    </symbol>
    <symbol id="icon-ListDelete" viewBox="0 0 32 32">
      <path d="M24.547 11.213l-4.773 4.787 4.773 4.787-3.76 3.76-4.787-4.773-4.787 4.773-3.76-3.76 4.773-4.787-4.773-4.787 3.76-3.76 4.787 4.773 4.787-4.773z" />
    </symbol>
    <symbol id="icon-CheckboxChecked" viewBox="0 0 32 32">
      <path d="M15.8 16.22l14.2-14.2v7.060l-14.2 14.2-8.18-8.18 3.54-3.54zM26 26v-7.96l4-4v11.96q0 1.656-1.172 2.828t-2.828 1.172h-20q-1.656 0-2.828-1.172t-1.172-2.828v-20q0-1.656 1.172-2.828t2.828-1.172h19.060l-4 4h-15.060v20h20z" />
    </symbol>
    <symbol id="icon-CheckboxUnchecked" viewBox="0 0 32 32">
      <path d="M26 2q1.656 0 2.828 1.172t1.172 2.828v20q0 1.656-1.172 2.828t-2.828 1.172h-20q-1.656 0-2.828-1.172t-1.172-2.828v-20q0-1.656 1.172-2.828t2.828-1.172h20zM26 26v-20h-20v20h20z" />
    </symbol>
    <symbol id="icon-RadiobuttonChecked" viewBox="0 0 32 32">
      <path d="M9 16q0-2.906 2.047-4.953t4.953-2.047 4.953 2.047 2.047 4.953-2.047 4.953-4.953 2.047-4.953-2.047-2.047-4.953zM16 1q4.063 0 7.516 2.016t5.469 5.469 2.016 7.516-2.016 7.516-5.469 5.469-7.516 2.016-7.516-2.016-5.469-5.469-2.016-7.516 2.016-7.516 5.469-5.469 7.516-2.016zM16 27q4.563 0 7.781-3.219t3.219-7.781-3.219-7.781-7.781-3.219-7.781 3.219-3.219 7.781 3.219 7.781 7.781 3.219z" />
    </symbol>
    <symbol id="icon-RadiobuttonUnchecked" viewBox="0 0 32 32">
      <path d="M16 1q4.063 0 7.516 2.016t5.469 5.469 2.016 7.516-2.016 7.516-5.469 5.469-7.516 2.016-7.516-2.016-5.469-5.469-2.016-7.516 2.016-7.516 5.469-5.469 7.516-2.016zM16 27q4.563 0 7.781-3.219t3.219-7.781-3.219-7.781-7.781-3.219-7.781 3.219-3.219 7.781 3.219 7.781 7.781 3.219z" />
    </symbol>
    <symbol id="icon-ArrowUp" viewBox="0 0 16 16">
      <path d="M8,3.525L1.763,9.763c-0.684,0.684-0.684,1.791,0,2.475s1.791,0.684,2.475,0L8,8.475l3.763,3.762  c0.342,0.342,0.789,0.513,1.237,0.513s0.896-0.171,1.237-0.513c0.684-0.684,0.684-1.791,0-2.475L8,3.525z" />
    </symbol>
    <symbol id="icon-ArrowDown" viewBox="0 0 16 16">
      <path d="M11.763,5.763L8,9.525L4.237,5.763c-0.684-0.684-1.791-0.684-2.475,0  c-0.684,0.683-0.684,1.792,0,2.475L8,14.475l6.237-6.237c0.684-0.683,0.684-1.792,0-2.475C13.554,5.079,12.446,5.079,11.763,5.763z" />
    </symbol>
    <symbol id="icon-Question" viewBox="0 0 1024 1024">
      <path d="M512 32q129 0 239.5 65t175.5 175.5 65 239.5-65 239.5-175.5 175.5-239.5 65-239.5-65-175.5-175.5-65-239.5 65-239.5 175.5-175.5 239.5-65zM614.4 761.6q0-33.527-28.758-58.363t-67.242-24.836-67.242 24.836-28.758 58.363 28.758 58.364 67.242 24.836q38.484 0 67.242-24.836t28.758-58.364zM729.6 454.4q19.2-38.399 19.2-89.601 0-72.534-51.2-115.2-53.759-44.8-147.199-44.8-115.201 0-211.2 57.601l44.8 102.399q80.639-44.8 153.6-44.8 25.599 0 64 12.8 19.2 19.199 19.2 44.8 0 32-12.8 44.801-3.715 5.572-8.069 10.557t-11.203 10.988-11.632 9.902-15.811 12.524-17.285 13.629q-36.572 29.257-64 70.4-19.2 19.2-19.2 57.6 38.397-12.8 57.601-12.8 19.202 0 57.6 12.8 3.2-3.2 6.4-12.8t6.4-12.799q25.601-25.601 57.601-44.801 2.43-2.025 7.211-6.001 22.739-18.909 33.816-28.6t23.805-23.505 18.367-25.093z" />
    </symbol>
    <symbol id="icon-Checkmark" viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </symbol>
    <symbol id="icon-Plus" viewBox="0 0 32 32">
      <path d="M31 12h-11v-11c0-0.552-0.448-1-1-1h-6c-0.552 0-1 0.448-1 1v11h-11c-0.552 0-1 0.448-1 1v6c0 0.552 0.448 1 1 1h11v11c0 0.552 0.448 1 1 1h6c0.552 0 1-0.448 1-1v-11h11c0.552 0 1-0.448 1-1v-6c0-0.552-0.448-1-1-1z" />
    </symbol>

    <symbol id="icon-Folder" viewBox="0 0 512 512">
      <path d="M224 64l64 64h224v352h-512v-416z" />
    </symbol>
    <symbol id="icon-FolderOpen" viewBox="0 0 512 512">
      <path d="M416 480l96-256h-416l-96 256zM64 192l-64 288v-416h144l64 64h208v64z" />
    </symbol>
    <symbol id="icon-FolderPlus" viewBox="0 0 512 512">
      <path d="M288 128l-64-64h-224v416h512v-352h-224zM352 352h-64v64h-64v-64h-64v-64h64v-64h64v64h64v64z" />
    </symbol>
    <symbol id="icon-Versions" viewBox="0 0 32 32">
      <path d="M32 10l-16-8-16 8 16 8 16-8zM16 4.655l10.689 5.345-10.689 5.345-10.689-5.345 10.689-5.345zM28.795 14.398l3.205 1.602-16 8-16-8 3.205-1.602 12.795 6.398zM28.795 20.398l3.205 1.602-16 8-16-8 3.205-1.602 12.795 6.398z" />
    </symbol>
    <symbol id="icon-Undo" viewBox="0 0 32 32">
      <path d="M16 2c-4.418 0-8.418 1.791-11.313 4.687l-4.686-4.687v12h12l-4.485-4.485c2.172-2.172 5.172-3.515 8.485-3.515 6.627 0 12 5.373 12 12 0 3.584-1.572 6.801-4.063 9l2.646 3c3.322-2.932 5.417-7.221 5.417-12 0-8.837-7.163-16-16-16z" />
    </symbol>

    {/* Me */}
    <symbol id="icon-Me-dark" viewBox="0 0 32 32">
      <g id="wrapper">
        <rect id="spacer" width="32" height="32" fillOpacity="0">  </rect>
        <g id="base">
          <polygon fill="#ffffff" fillOpacity="0.9" points="22.8,1 19.94,1 17.72,5 20.58,5  ">   </polygon>
          <path fill="#ffffff" fillOpacity="0.9" d="M24,7h-6.89l1.11,2l0.07,0.12L18.36,9H24v19H8V9h3.93l-1.11-2H8C6.9,7,6,7.9,6,9v19c0,1.1,0.9,2,2,2   h16c1.1,0,2-0.9,2-2V9C26,7.9,25.1,7,24,7z">   </path>
          <path fill="#ffffff" fillOpacity="0.9" d="M16,11.92c0.21,0,0.41-0.05,0.61-0.16c0.6-0.34,0.82-1.1,0.49-1.7L12.06,1H9.2l5.71,10.27   C15.14,11.68,15.56,11.92,16,11.92z">   </path>
        </g>
        <g id="accent">
          <path fill="#ffffff" fillOpacity="0.6" d="M11,27h10c0-3.43-1.35-6.35-3.25-7.49c1.04-0.61,1.75-1.72,1.75-3.01c0-1.56-1.03-2.87-2.44-3.32   C16.72,13.07,16.37,13,16,13c-0.37,0-0.72,0.07-1.06,0.18c-1.41,0.45-2.44,1.76-2.44,3.32c0,1.29,0.71,2.41,1.75,3.01   C12.35,20.65,11,23.57,11,27z">   </path>
        </g>
      </g>
    </symbol>

    {/* Theme dark */}
    <symbol id="icon-DisplayThemeDark-dark" viewBox="0 0 24 24">
      <g id="wrapper">
        <rect id="spacer" width="24" height="24" fillOpacity="0">  </rect>
        <g id="accent">
          <path fill="#ffffff" fillOpacity="0.6" d="M8,2.53c1.65,0,3.31,0.16,4.96,0.47c0.35,1.08,0.55,2.16,0.59,3.23c0.38-0.05,0.75-0.07,1.11-0.07   c0.3,0,0.6,0.02,0.9,0.05c-0.05-1.28-0.28-2.55-0.7-3.83c-0.23-0.7-0.82-1.21-1.53-1.34C11.56,0.7,9.77,0.53,8,0.53   S4.44,0.7,2.67,1.04c-0.71,0.13-1.3,0.65-1.53,1.34c-0.96,2.9-0.96,5.85,0,8.76c0.23,0.69,0.81,1.2,1.53,1.34   c1.32,0.25,2.65,0.4,3.98,0.47c0,0.02-0.01,0.04-0.01,0.05c-0.34,1.13-1.13,1.95-2.58,1.99V15H3.5c-1.1,0-2,0.9-2,2h12.8   c0.03-0.23,0.06-0.47,0.07-0.71C14.08,15.54,13.35,15,12.5,15h-0.56v-0.01C10.5,14.95,9.7,14.13,9.36,13   c-0.01-0.02-0.01-0.04-0.01-0.05c1.33-0.06,2.66-0.22,3.98-0.47c0.03-0.01,0.05-0.02,0.07-0.02c-0.4-0.66-0.9-1.27-1.51-1.78   c-1.3,0.19-2.6,0.29-3.9,0.29c-1.65,0-3.3-0.16-4.96-0.47c-0.83-2.5-0.83-5,0-7.5C4.7,2.69,6.35,2.53,8,2.53z">   </path>
        </g>
        <g id="base">
          <path fill="#ffffff" fillOpacity="0.9" d="M22,16c0-4.05-3.28-7.34-7.34-7.34c-0.4,0-0.79,0.04-1.16,0.1c2.07,1.73,3.39,4.33,3.39,7.24   c0,2.91-1.32,5.51-3.39,7.24c0.38,0.06,0.76,0.1,1.16,0.1C18.72,23.34,22,20.06,22,16z">   </path>
        </g>
      </g>
    </symbol>
    {/* Theme light */}
    <symbol id="icon-DisplayThemeLight-dark" viewBox="0 0 24 24">
      <g id="wrapper">
        <rect id="spacer" width="24" height="24" fillOpacity="0">  </rect>
        <g id="accent">
          <path fill="#ffffff" fillOpacity="0.6" d="M15.57,6.99c0.03-1.54-0.21-3.08-0.71-4.61c-0.23-0.7-0.82-1.21-1.53-1.34   C11.56,0.7,9.77,0.53,8,0.53S4.44,0.7,2.67,1.04c-0.71,0.13-1.3,0.65-1.53,1.34c-0.96,2.9-0.96,5.85,0,8.76   c0.23,0.69,0.81,1.2,1.53,1.34c1.32,0.25,2.65,0.4,3.98,0.47c0,0.02-0.01,0.04-0.01,0.05c-0.34,1.13-1.13,1.95-2.58,1.99V15H3.5   c-1.1,0-2,0.9-2,2h6c-0.04-0.33-0.06-0.66-0.06-1s0.02-0.67,0.06-1c0.12-1.07,0.43-2.08,0.89-3c0.18-0.36,0.38-0.72,0.6-1.05   C8.66,10.96,8.33,10.97,8,10.97c-1.65,0-3.3-0.16-4.96-0.47c-0.83-2.5-0.83-5,0-7.5C4.7,2.69,6.35,2.53,8,2.53S11.31,2.69,12.96,3   c0.49,1.48,0.68,2.96,0.59,4.45C14.19,7.22,14.87,7.06,15.57,6.99z">   </path>
        </g>
        <g id="base">
          <path fill="#ffffff" fillOpacity="0.9" d="M20.65,13.1l0.37-2.52l0.12-0.85l-0.76,0.38l-2.3,1.13l-1.2-2.28L16.5,8.23l-0.38,0.73l-1.2,2.28   l-2.3-1.13l-0.76-0.38l0.12,0.85l0.37,2.52L9.9,13.52L9,13.68l0.65,0.66l1.72,1.77l-1.68,1.73L9,18.55l0.99,0.17l2.36,0.41   l-0.34,2.31l-0.15,1.05l0.97-0.47l2.09-1.03l1.07,2.04L16.5,24l0.51-0.97l1.07-2.04l2.09,1.03l0.97,0.47l-0.15-1.05l-0.34-2.31   l2.36-0.41L24,18.55l-0.69-0.71l-1.68-1.73l1.72-1.77L24,13.68l-0.9-0.16L20.65,13.1z M16.5,20c-2.21,0-4-1.79-4-4   c0-2.21,1.79-4,4-4s4,1.79,4,4C20.5,18.21,18.71,20,16.5,20z">   </path>
        </g>
      </g>
    </symbol>

    {/* Log off */}
    <symbol id="icon-LogOff-dark" viewBox="0 0 24 24">
      <g id="wrapper">
        <rect id="spacer" width="24" height="24" fillOpacity="0">  </rect>
        <path fill="#ffffff" fillOpacity="0.9" d="M18.82,2.36C16.74,1.5,15.36,2,14.97,5.93c-0.25,2.48,0.27,3.64,0.34,4.6l4.32,0.9  c0.05-0.22,0.1-0.43,0.15-0.64c0.18-0.89,0.87-1.79,1.06-2.8C21.4,4.91,20.46,3.03,18.82,2.36z">  </path>
        <path fill="#ffffff" fillOpacity="0.9" d="M16.39,16.62c1.33,0.28,2.31-1.58,2.88-3.5l-4.17-0.86C14.94,14.29,14.82,16.3,16.39,16.62z">  </path>
        <path fill="#ffffff" fillOpacity="0.9" d="M6.08,7.71c-1.64,0.68-2.58,2.55-2.01,5.62c0.19,1,0.88,1.9,1.06,2.8c0.04,0.21,0.09,0.42,0.15,0.64  l4.32-0.9c0.07-0.96,0.59-2.12,0.34-4.6C9.54,7.35,8.16,6.85,6.08,7.71z">  </path>
        <path fill="#ffffff" fillOpacity="0.9" d="M5.63,18.47c0.57,1.92,1.54,3.78,2.88,3.5c1.57-0.33,1.45-2.33,1.29-4.37L5.63,18.47z">  </path>
      </g>
    </symbol>

    {/* About */}
    <symbol id="icon-About-dark" viewBox='0 0 24 24'>
      <g id='wrapper'>
        <rect id='spacer' width='24' height='24' fillOpacity='0'>  </rect>
        <path fill='#ffffff' fillOpacity='0.9' d='M12,1C5.926,1,1,5.925,1,12s4.926,11,11,11c6.073,0,11-4.924,11-11S18.073,1,12,1z M12,4.778  c1.381,0,2.5,0.995,2.5,2.222c0,1.227-1.119,2.222-2.5,2.222c-1.381,0-2.5-0.995-2.5-2.222C9.5,5.773,10.619,4.778,12,4.778z M14,19  h-4v-8.238c0.605,0.285,1.278,0.46,2,0.46s1.395-0.175,2-0.46V19z'>  </path>
      </g>
    </symbol>

    {/* Help */}
    <symbol id="icon-Help-dark" viewBox='0 0 32 32'>
      <g id='wrapper'>
        <rect id='spacer' width='32' height='32' fillOpacity='0'>  </rect>
        <path fill='#ffffff' fillOpacity='0.9' d='M9,3C7.34,3,6,4.34,6,6v20c0,1.66,1.34,3,3,3h17v-2H10c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h2h14V9V6V3  H9z'>  </path>
      </g>
    </symbol>

    {/* Advertisement */}
    <symbol id="icon-Advertisement-dark" viewBox='0 0 24 24'>
      <g id='wrapper'>
        <rect id='spacer' width='24' height='24' fillOpacity='0'>  </rect>
        <g id='base'>
        <path fill='#ffffff' fillOpacity='0.9' d='M11,8v8c4.902,0,9.203,1.738,11,4.022V3.978C20.203,6.262,15.902,8,11,8z'>   </path>
        </g>
        <g id='accent'>
        <path fill='#ffffff' fillOpacity='0.6' d='M7.719,18.711c-0.275,0-0.346-0.073-0.373-0.102c-0.264-0.273-0.376-1.021-0.334-2.222l0.005-0.13   C7.019,16.17,7.011,16.084,6.999,16H9v-1v-1v-4V9V8H3C2.448,8,2,8.448,2,9v6c0,0.553,0.448,1,1,1h1.04   c-0.008,0.055-0.02,0.108-0.022,0.164l-0.004,0.119c-0.035,1-0.108,3.082,1.174,4.41c0.643,0.666,1.518,1.018,2.531,1.018   c0.829,0,1.5-0.672,1.5-1.5S8.547,18.711,7.719,18.711z'>   </path>
        </g>
      </g>
    </symbol>
  </svg>;
};