import React from 'react';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { SrComponentPropsBase, SrComponent } from '../BasicTypes';
import { schema } from './Schema';

import { SrtContainer } from './SrtContainer';
import { HrReactComponent } from './SectraStructure';

interface SectionProps extends SrComponentPropsBase {
  display?: boolean;
  hidden?: boolean;
  borderTop?: boolean,
  borderBottom?: boolean,
}

const sectionSchema = {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'borderTop': { 'type': 'boolean', 'description': 'Display a border above the section content (default: false)', 'enumDescription': ['Display border', 'No border (default)'] },
  'borderBottom': { 'type': 'boolean', 'description': 'Display a border below the section content (default: false)', 'enumDescription': ['Display border', 'No border (default)'] },
  'hidden': schema.PropDefinition.hidden,
  'display': schema.PropDefinition.display,
};

export const SectraSectionReactComponent: React.FC<ReactComponentContainerProps<SectionProps>> = (container) => {
  const props = container.props;
  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  return (
    <div className={'SectraSection'} hidden={props.hidden === true}>
        {props.borderTop === true ? <HrReactComponent props={props} context={container.context} templateContext={container.templateContext} functions={container.functions} /> : null}
        <SrtContainer parentId={props.id} renderContext={container.context}  templateContext={container.templateContext} functions={container.functions} />
        {props.borderBottom === true ? <HrReactComponent props={props} context={container.context}  templateContext={container.templateContext} functions={container.functions} /> : null}
    </div>);
};

const key = 'ComponentGroup';
function render(props: any, context: any, templateContext: any, functions: any) {
  return <SectraSectionReactComponent props={props} context={context} templateContext={templateContext} functions={functions}/>;
}
function template() {
  return Promise.resolve(`- ${key}:
    id: componentGroup${schema.getNextIdNum()}
    borderBottom: true
    components:
    - Header:
        header: MyHeader
    `);
}

export const ComponentGroup : SrComponent<SectionProps> = {
  key,
  render,
  template,
  toolboxName: key,
  schema: schema.getSchema(key, schema.mergeSchemaProps(schema.DefaultComponentSchemaPart, sectionSchema), ['id'], false),
};

const sectionKey = 'SectraSection';
export const SectraSection : SrComponent<SectionProps> = {
  key: sectionKey,
  render,
  template,
  toolboxName: sectionKey,
  schema: schema.getSchema(sectionKey, schema.mergeSchemaProps(schema.DefaultComponentSchemaPart, sectionSchema), ['id'], false),
};