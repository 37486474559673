/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useLayoutEffect } from 'react';
import { SectraCheckButton } from './SectraBaseComponent/SectraCheckButtonOverride';
import { fontSizeToHtmlFontSizeProp, HTMLOutputSettings, SectraTextArea } from './SectraBaseComponent/SectraTextAreaContentEditable';
import { Col, Grid, Row } from 'react-bootstrap';
import './Summary.scss';
import { CodeSection, CodeSectionsResponse, ExplicitSetterType, ReportTextFormatting, ReportTextPart, SrComponent, SrComponentPropsBase } from '../BasicTypes';
import { applySmartCodeSectionFilter, codeSectionPartsToText } from '../CodeSections';
import { ReactComponentContainerProps, shouldDisplay } from './SrtComponent';
import { expressionPattern, schema } from './Schema';

import { usePrevious } from '../ReactExt';
import { applogger } from '../../applogger';
import { PreFont } from '../TemplateDefaults';
import { getWidth } from '../../Helpers/RTFHelper';


const summarySchema = {
  'id': { 'type': 'string', 'description': 'The id of the element' },
  'hidden': schema.PropDefinition.hidden,
  'display': schema.PropDefinition.display,
  'label': { 'type': ['string', 'null'], 'description': 'The summary label (empty: no label shown, default)' },
  'editable': { 'type': ['string', 'boolean'], 'enumDescription': ['Report text is editable', 'Report text is not editable (default)'], 'description': 'Whether to allow user edit or not (default: false)', 'pattern': expressionPattern },
  'editModeText': { 'type': ['string', 'null'], 'description': 'The toggle edit mode text (default: [generic text]).' },
  'allowInconsistency': { 'type': 'boolean', 'enumDescription': ['Inconsistency is allowed, i.e. a non-consistant user input value will be retained and communicated further into the report', "Not allowed, i.e. a non-consistant user input value won't be communicated into the resulting report (default)"], 'description': 'When there is a new calculated text available, but user is in edit report mode, allowInconsistency allows user to remain in edit mode after a form state change (default: false).' },
  'inconsistencyWarning': { 'type': 'string', 'description': 'If there is an inconsistency, show this warning (default: [generic warning]).' },
  'inconsistencyWarningAllowed': { 'type': 'string', 'description': 'If inconsistency is allowed, and there is an inconsistency, show this warning (default: [generic warning]).' },
  'inconsistencyWarningReferenced': { 'type': 'string', 'description': 'If there is an inconsistency in a referenced summary component, show this warning (default: [generic warning]).' },
  'smartFilterStyle': { 'type': 'boolean', 'enumDescription': ['Apply smart filtering (default)', 'No smart filtering'], 'description': 'Use smart filter style of writing the report (default: true)' },
  'checked': { 'type': ['string', 'boolean'], 'description': "If editable, dictates whether we're in edit mode or not (default: false)", 'enumDescription': ['Checked', 'Not checked (default)'], 'pattern': expressionPattern },
  'reportTemplate': {
    'type': 'array',
    'items': {
      'type': 'object',
      'required': ['text'],
      'properties': {
        'text': { 'type': 'string', 'description': 'Text to go into the report. If multiple they will be appended.' },
        'if': { 'type': 'string', 'description': 'If the text should be included or not' },
        'separator': { 'type': 'string', 'description': 'The separator to have between the previous item and this item (default: [newline])' },
        'smartFilter': { 'type': 'boolean', 'enumDescription': ['Apply smart filtering (default) for this report summary item', 'No smart filtering for this report summary item'], 'description': 'Use smart filter style for this report summary item (overrides any setting from _smartFilterStyle_, default: use _smartFilterStyle_ setting)' },
      },
    },
    'description': 'The report text (required)',
  },
};

export interface TextAndCondition {
  text: string;
  if?: string;
  separator?: string;
  smartFilter?: boolean;
  sectionId?: string;
}

export interface SummaryProps extends SrComponentPropsBase {
  editable?: boolean;
  editModeText?: string;
  allowInconsistency?: boolean;
  inconsistencyWarning?: string;
  inconsistencyWarningAllowed?: string;
  inconsistencyWarningReferenced?: string;
  smartFilterStyle?: boolean;
  checked?: boolean;
  hidden?: boolean;
   
  reportTemplate: TextAndCondition[]; // the report template written in yaml
  calculatedReport?: string;          // the report text when calculated
  userEditedReport?: string;          // the report text when overridden by the user
  preEditModeCalculatedReport?: string;     // the calculated report text when entering edit mode
  referencedSummaryInconsistency?: boolean;

  value?: string;                     // the report text, either overridden by the user or calculated
}

export const SummaryComp: React.FC<ReactComponentContainerProps<SummaryProps>> = (container) => {
  const props = container.props;

  const prevChecked = usePrevious(props.checked === true);
  useLayoutEffect(() => {
    if (!prevChecked && props.checked === true) {
      document.getElementById(props.id)?.focus();
    }
  }, [props.checked]);

  if (!shouldDisplay(props.display, container.context)) {
    return null;
  }

  if (props.hidden === true) {
    // No need to render, hidden is however important to use when you would like to use the output in the report
    return null;
  }
    
  const allowEdit = props.editable ?? false;
  const inEditMode = allowEdit && props.checked === true;
  if (allowEdit && inEditMode
         && ((props.calculatedReport != null && props.preEditModeCalculatedReport == null)
          || (prevChecked !== props.checked && props.calculatedReport !== props.preEditModeCalculatedReport))) {
    // handle that we're starting in edit mode or programatically changing it
    setTimeout(() => {
      container.functions.compositeUpdate([
        { componentId: props.id, propName: 'userEditedReport', value: props.calculatedReport },
        { componentId: props.id, propName: 'preEditModeCalculatedReport', value: props.calculatedReport },
      ]);
    }, 0);
  }

  const componentInconsistency = inEditMode && props.calculatedReport !== props.preEditModeCalculatedReport && props.calculatedReport != props.userEditedReport && props.preEditModeCalculatedReport !== undefined;
  const hasUnallowedInconsistency = componentInconsistency && !(props.allowInconsistency ?? false);
  const templateDefaults = container.templateContext.defaults;
    
  let warningText: string | null = null;
  if (hasUnallowedInconsistency) {
    warningText = props.inconsistencyWarning ?? templateDefaults.summaryInconsistencyWarningText;
  } else if (componentInconsistency) {
    warningText = props.inconsistencyWarningAllowed ?? templateDefaults.summaryInconsistencyWarningAllowedText;
  } else if (props.referencedSummaryInconsistency === true) {
    warningText = props.inconsistencyWarningReferenced ?? templateDefaults.summaryInconsistencyWarningReferencedText;
  }

  const output: JSX.Element[] = [];
  if (allowEdit) {
    // Add a checkbox to toggle in edit mode
    output.push(<SectraCheckButton
            key={'checkbox'}
            value={props.editModeText ?? templateDefaults.summaryDefaultEditableLabel}
            name={props.id}
            checked={inEditMode}
            onStateChange={(b: boolean) => { 
              if (b) {
                // we are now entering edit mode, lets set that we do enter edit mode but also save a copy of the report text as of now
                container.functions.compositeUpdate([
                  { componentId: props.id, propName: 'checked', value: b },
                  { componentId: props.id, propName: 'userEditedReport', value: props.calculatedReport },
                  { componentId: props.id, propName: 'preEditModeCalculatedReport', value: props.calculatedReport },
                ]);
              } else {
                container.functions.setUserInput(props.id, 'checked', b);
              }

            }} />);
  }

  if (warningText != null && String(warningText).length > 0) {
    output.push(<div key={'warning'} className="summaryInconsistencyWarning">{warningText}</div>);
  }

  const opts: HTMLOutputSettings = {
    pre: templateDefaults.defaultPreFont,
    normalFont: templateDefaults.reportNormalFont,
    normalSize: fontSizeToHtmlFontSizeProp(templateDefaults.reportNormalTextSize),
    smallSize: fontSizeToHtmlFontSizeProp(templateDefaults.reportSmallTextSize),
    h1Size: fontSizeToHtmlFontSizeProp(templateDefaults.reportH1TextSize),
    h2Size: fontSizeToHtmlFontSizeProp(templateDefaults.reportH2TextSize),
    h3Size: fontSizeToHtmlFontSizeProp(templateDefaults.reportH3TextSize),
  };

  if (inEditMode) {
    output.push(<SectraTextArea
            key={'text'}
            id={props.id}
            className={'form-control textarea summary-edit'}
            value={trimTrailingNewline(props.userEditedReport)}
            disabled={hasUnallowedInconsistency ? true : undefined}
            markupCustomStylingOnPaste={true}
            renderOpts={opts}
            onUpdate={(val: string | null) => {
              if (props.userEditedReport != null && props.userEditedReport.endsWith('\n')) {
                val = (val ?? '') + '\n';
              }

              container.functions.setUserInput(props.id, 'userEditedReport', val, false);
              container.functions.runScripts(200, [{ type: 'setUserInput', compId: props.id, propName: 'userEditedReport' }]);
            }}
        />);

    if (hasUnallowedInconsistency) {
      output.push(<div key={'content'} className="SectraSummary spacing">
                {getHtmlOutput(getTextOutputItems(props.calculatedReport), opts)}
            </div>);
    }
  } else {
    output.push(<div key={'content'} className="SectraSummary">
            {getHtmlOutput(getTextOutputItems(props.calculatedReport), opts)}
        </div>);
  }
    
  // note: use src label since label may be a forwarded value from a down-stream component (special feature of the label property)
  const label = props.label ?? (!allowEdit ? templateDefaults.summaryDefaultLabel : null);

  return <Grid data-component-id={props.id}>
        <Row className={'show-grid fullwidth'}>
            <Col xs={12}>
                <div>
                    {label != null
                      ? <label htmlFor={props.id} style={{ margin: '0 0 4px 2px' }}>{label}</label>
                      : null}
                    {output}
                </div>
            </Col>
        </Row>
    </Grid>;
};

const summaryComponentKey = 'Summary';
export const Summary : SrComponent<SummaryProps> = {
  getCodeSections: getCodeSections,
  key: summaryComponentKey,
  render: (props, context, templateContext, functions) => <SummaryComp props={props} context={context} templateContext={templateContext} functions={functions}/>,
  template: () => Promise.resolve(`- ${summaryComponentKey}:
    id: summary${schema.getNextIdNum()}
    reportTemplate:
    - text: | 
        <em>Use braces to include variables or expressions, e.g. </em><pre>{'{&lt;variable&gt;}'}</pre>
        <em>In smart filter mode, parts with references to undefined variables are removed. Eg.</em>

        Value: "{'text'}" is part of the report, while value: {null} isn't. 
        Not included row due to row condition separated by dubble-pipe || {null}
        This was all for now. || {[undefined,"included if any item is not false or null"]}
      `),
  toolboxName: summaryComponentKey,
  schema: schema.getSchema(summaryComponentKey, summarySchema, ['reportTemplate'], false),
  onStateChangeRunner: onStateChangeRunner,
};

// Register reportTemplate as a code section where result is stored into the calculatedReport property
function getCodeSections(componentsYamlArgs: SummaryProps): CodeSectionsResponse<SummaryProps>[] {
  //applogger.debug('summary getCodeSections', componentsYamlArgs.reportTemplate);
  return [{
    propNameRead: 'reportTemplate',
    propNameToSet: 'calculatedReport',
    codeSections: componentsYamlArgs.reportTemplate.map(tnc => {
      let ret: CodeSection = {
        sectionText: tnc.text,
        condition: tnc.if,
        codeSectionSpacerString: tnc.separator ?? '\n',
        smartFilter: tnc.smartFilter,
        outputSection: tnc.sectionId
      };
      return ret;
    }),
    customEvaluate: (props, prefix, expressionRunner, get, set, templateContext) => {
      const rawParts = expressionRunner(prefix) ?? [];
      const langCode = templateContext.langCode;
            
      const components = Object.keys(rawParts.reduce((l, p) => {
        if (p.components != null && Array.isArray(p.components)) {
          p.components.forEach(c => l[c] = true);
        }
        return l;
      }, {} as { [componentId: string]: boolean }));


      const referencedSummaryInconsistency = components
        .filter(c => templateContext.runtime.getComponentById(c)?.componentName === summaryComponentKey)
        .some(c => {
          if (get(c, 'referencedSummaryInconsistency') === true) return true;
          const preEditModeReport = get(c, 'preEditModeCalculatedReport');
          if (preEditModeReport === undefined) return false;

          const allowEdit = (get(c, 'editable') ?? false);
          const inEditMode = allowEdit && (get(c, 'checked') === true);
          return inEditMode && preEditModeReport !== get(c, 'calculatedReport');
        });

      set(props.id, 'referencedSummaryInconsistency', referencedSummaryInconsistency, false);  

      return codeSectionPartsToText(applySmartCodeSectionFilter(rawParts, props.smartFilterStyle !== false), langCode);
    },
  }];
}

function trimTrailingNewline(str: string | null | undefined) {
  if (str == null) return str;
  return str.replace(/\r?\n$/, '');
}

function onStateChangeRunner(props: SummaryProps, set: ExplicitSetterType) {
  const allowEdit = props.editable ?? false;
  const inEditMode = allowEdit && props.checked === true;
  const allowInconsistency = props.allowInconsistency ?? false;
  const inconsistancy = props.calculatedReport !== props.preEditModeCalculatedReport && props.calculatedReport != props.userEditedReport;

  // Set the value prop as always reflecting component value regardless of edit mode or not
  const value = inEditMode && (!inconsistancy || allowInconsistency) ? props.userEditedReport : props.calculatedReport;
  set(props.id, 'value', value, true);
}

/*
 * Code for handling report text formatting
 */
export function getTextOutputItems(reportText?: string): ReportTextPart[] {
  if (reportText == null) {
    return [];
  }

  // try remove any non-accepted tags by encoding
  reportText = reportText.replace(/<(\/?)(?!(em|i|strong|b|pre|mono|rsection|small|h1|h2|h3|table|thead|tbody|tr|td))(\w+)/gi, '&lt;$1$3');

  // read data into a dom parser
  let ret: ReportTextPart[] = [];
  const domparser = new DOMParser();
  const doc = domparser.parseFromString(reportText, 'text/html');
  const bodyNodes = doc.getElementsByTagName('body');
  if (bodyNodes.length === 0) {
    return ret;
  }

  const baseNode = bodyNodes[0];
  const recursiveDomVisit = (node: Node, formattingScope: ReportTextFormatting, sectionId: string | undefined = undefined) => {
    node.childNodes.forEach(childNode => {
      switch (childNode.nodeType) {
        case Node.TEXT_NODE: 
          if (childNode.textContent != null) {
            if (formattingScope.tableContext === 'td') {
              ret[ret.length - 1].text = childNode.textContent;
              ret[ret.length - 1].styling = formattingScope;
            } else {
              ret.push({ text: childNode.textContent, styling: formattingScope, sectionId: sectionId });
            }
          }
          break;
        case Node.ELEMENT_NODE: {
          let e = childNode as Element;
          let childScope = { ...formattingScope };
          switch (e.tagName) {
            case 'RSECTION': {
              sectionId = e.getAttribute("id") ?? undefined;
              ret.push({ text: '', styling: childScope, sectionId: sectionId });
              break;
            }
            case 'TABLE': {
              if (formattingScope.tableContext !== 'none') {
                applogger.warn('<table> not supported inside another table');
                break;
              }
              const width: 'xs' | 'sm' | 'md' | 'lg' = e.getAttribute('width') as 'xs' | 'sm' | 'md' | 'lg' ?? 'md';
              const minColumnWidth: number = parseInt(e.getAttribute('minColumnWidth') ?? '20');
              const columnWidths: (number | null | undefined)[] | undefined = e.getAttribute('columnWidths')?.replace(/ /g, '')?.split(',')?.map(x => isNaN(parseInt(x)) ? null : parseInt(x));
              childScope.tableContext = 'table';
              childScope.tableWidth = width;
              childScope.minColumnWidth = minColumnWidth;
              childScope.columnWidths = columnWidths;
              ret.push({ text: '', tableHtml: e.innerHTML, styling: childScope, sectionId: sectionId });
              break;
            }
            case 'THEAD':
              if (formattingScope.tableContext !== 'table') {
                applogger.warn('<thead> only supported as a direct decendant to a <table>-tag');
                break;
              }
              childScope.tableContext = 'thead';
              ret.push({ text: '', styling: childScope, sectionId: sectionId });
              break;
            case 'TBODY':
              if (formattingScope.tableContext !== 'table') {
                applogger.warn('<tbody> only supported as a direct decendant to a <table>-tag');
                break;
              }
              childScope.tableContext = 'tbody';
              ret.push({ text: '', styling: childScope, sectionId });
              break;
            case 'TR':
              if (formattingScope.tableContext !== 'table' && formattingScope.tableContext !== 'thead'
                                && formattingScope.tableContext !== 'tbody') {
                applogger.warn('<tr> only supported as a direct decendant to a <table>-, <thead>- or <tbody>-tag.');
                break;
              }
              childScope.tableContext = 'tr';
              ret.push({ text: '', styling: childScope, sectionId });
              break;
            case 'TD':
              if (formattingScope.tableContext !== 'tr') {
                applogger.warn('<td> only supported as a direct decendant to a <tr>-tag');
                break;
              }
              childScope.tableContext = 'td';
              ret.push({ text: '', styling: childScope, sectionId });
              break;
            case 'BR':
              ret.push({ text: '\n', styling: childScope, sectionId });
              break;
            case 'EM':
            case 'I':
              childScope.emphasis = true;
              break;
            case 'STRONG':
            case 'B':
              childScope.strong = true;
              break;
            case 'PRE':
            case 'MONO':
              childScope.preformat = true;
              break;
            case 'SMALL':
              childScope.small = true;
              break;
            case 'H1':
              childScope.h1 = true;
              break;
            case 'H2':
              childScope.h2 = true;
              break;
            case 'H3':
              childScope.h3 = true;
              break;
            default:
              applogger.warn(`tagName: ${e.tagName} not supported`);
          }

          // visit children
          recursiveDomVisit(childNode, childScope, sectionId);
          break;
        }
      }

    });
  };

  recursiveDomVisit(baseNode, { emphasis: false, strong: false, preformat: false, small: false, h1: false, h2: false, h3: false, tableContext: 'none' });
  // reduce set when multiple text segments share the same styling
  return ret.length > 1 ? ret.reduce((acc, item)=> {
    if (acc.length === 0) {
      acc.push(item);
      return acc;
    }

    // add or extend
    let last = acc[acc.length - 1];
    if ( last.styling.preformat === item.styling.preformat
          && last.styling.strong === item.styling.strong
          && last.styling.emphasis === item.styling.emphasis
          && last.styling.small === item.styling.small
          && last.styling.h1 === item.styling.h1
          && last.styling.h2 === item.styling.h2
          && last.styling.h3 === item.styling.h3
          && last.sectionId === item.sectionId
          && item.styling.tableContext === 'none'
          && last.styling.tableContext === 'none') {
      last.text += item.text;
    } else {
      acc.push(item);
    }
    return acc;
  }, [] as ReportTextPart[]) : ret;
}

interface ReportOptions {
  pre: PreFont;
  normalFont: string | null;
  normalSize: number | null;
  smallSize: number | null;
  h1Size: number | null;
  h2Size: number | null;
  h3Size: number | null;
}

export function getReport(parts: ReportTextPart[], opts: ReportOptions): JSX.Element[] {
  const texts = parts.map(p => p.text);
  const separatorModifedParts = parts.map((p, i) => {
    let text = texts[i];
    if (text === '' && (i <= 0 || parts[i].sectionId == parts[i-1].sectionId)) {
      // we can just omit this empty entry
      return null;
    }

    const family = p.styling.preformat ? opts.pre : (opts.normalFont ?? '');
    const style =  [p.styling.strong || p.styling.h1 || p.styling.h2 || p.styling.h3  ? 'Bold' : null, p.styling.emphasis ? 'Italic' : null].filter(x => x != null).join(' ');

    let exactSize: number | null = null;
    if (p.styling.h1) {
      exactSize = opts.h1Size ?? (opts.normalSize ?? 9) * 2;
    } else if (p.styling.h2) {
      exactSize = opts.h2Size ?? (opts.normalSize ?? 9) * 1.5;
    } else if (p.styling.h3) {
      exactSize = opts.h3Size ?? (opts.normalSize ?? 9) * 1.17;
    } else if (p.styling.small) {
      exactSize = opts.smallSize;
    } else {
      exactSize = opts.normalSize;
    }

    const size = exactSize != null ? Math.round(exactSize)/*IDS7 currently doesn't support decimals through this interface*/.toString() : '';
    const fontAttr = family !== '' || style !== '' || size !== '' 
      ? `${(family)},${size},${style}`.replace(/,+$/, '')
      : undefined;

    let textSeparatorIsNewline = false;
    const isOnlyNewlines = !text.split('\n').some(x => x.length > 0);
    if (text.endsWith('\n') && !isOnlyNewlines) {
      // Remove the trailing new line character as it will be added be the field separation in IDS7
      textSeparatorIsNewline = true;
      text = text
        .substr(0, text.length - 1);
    } else if (text.endsWith(' ')) {
      // Remove the trailing space since it will be added be the field separation in IDS7
      text = text
        .substr(0, text.length - 1);
    } else {
      const nextText = texts.length > (i + 1) ? texts[i + 1] : undefined;
      if (nextText != null) {
        if (nextText.startsWith('\n')) {
          textSeparatorIsNewline = true;
          texts[i + 1] = nextText.substr(1);
        } else if (nextText.startsWith(' ')) {
          texts[i + 1] = nextText.substr(1);
        } else if (nextText.length >= 2 && (nextText[1] === ' ' || nextText[1] === '\n') && [':', '.', ','].indexOf(nextText[0]) >= 0) {
          text += nextText[0];
          texts[i + 1] = nextText.substr(2);
          textSeparatorIsNewline = nextText[1] === '\n';
        } else {
          // This text cannot be 100% rendered correctly in IDS7
          applogger.warn('Text section part cannot be fully represented in IDS7 as a space will be forced in-between sections with different styling.');
        }
        if (isOnlyNewlines) {
          for (let j = 0; j < text.split('\n').length - 1; j++) {
            texts[i + 1] = '\n' + texts[i + 1];
          }
        }
      } else {
        // This is the end of the report, lets end with a newline
        textSeparatorIsNewline = true;
      }
    }
        
    return {
      fieldNewline: textSeparatorIsNewline,
      text: text,
      font: fontAttr,
      sectionId: p.sectionId
    };
  })
    .filter(x => x !== null);

  return separatorModifedParts.map((x, i) => x != null
    ? <div key={i} style={{ height:0, visibility: 'hidden' }}>
            <label htmlFor={'reportPart' + i}></label>
            <textarea data-no-field-newline={!x.fieldNewline ? true : undefined} id={'reportPart' + i} name={'reportPart' + i} key={i}
              hidden={true} value={x.text} data-field-type="text" data-font={x.font} data-section-id={x.sectionId} readOnly={true} />
            {x.sectionId ? <input type="text" name={'reportPart' + i + 'sectionId'} data-field-type="text" value={x.sectionId} data-skip-in-report="yes" readOnly={true} /> : null}
        </div>
    : <></>);
}

function getHtmlOutput(parts: ReportTextPart[], opts: HTMLOutputSettings): JSX.Element[] {
  return parts.filter(x => x.styling.tableContext === 'none' || x.styling.tableContext === 'table').map((p, i) => {
    let style: React.CSSProperties = {};
    if (p.styling.tableContext === 'table') {
      if (p.tableHtml != null) {
        return <div style={{ width: getWidth(p.styling.tableWidth), maxWidth: '100%' }}><table style={{ width: '100%' }} className="outputTable" key={'outTable' + i} dangerouslySetInnerHTML={{ __html: p.tableHtml }}></table></div>;
      }
    }
    if (p.styling.preformat) {
      style.fontFamily = opts.pre;
    } else if (opts.normalFont !== '' && opts.normalFont !== null) {
      style.fontFamily = opts.normalFont;
    }
    if (p.styling.emphasis) {
      style.fontStyle = 'italic'; 
    }
    if (p.styling.strong || p.styling.h1 || p.styling.h2 || p.styling.h3) {
      style.fontWeight = 'bold';
    }
    if (p.styling.h1) {
      style.fontSize = opts.h1Size;
    } else if (p.styling.h2) {
      style.fontSize = opts.h2Size;
    } else if (p.styling.h3) {
      style.fontSize = opts.h3Size;
    } else if (p.styling.small) {
      style.fontSize = opts.smallSize;
    } else if (opts.normalSize !== '') {
      style.fontSize = opts.normalSize;
    }

    return <span key={i} style={style}>{p.text}</span>;
  });
} 
