import { TemplateDefaults } from './TemplateDefaults';

// Template context
export interface TemplateContext {
  // Env information
  inBuildMode: boolean;
  langCode?: string;

  // Template information
  templateSpec: ParsedTemplateSpec;
  defaults: TemplateDefaults;
    
  // Runtime
  runtime: ITemplateRuntime;
  componentStore: IComponentStateStore;

  setCustomStateExecuted: boolean;
}

export interface ITemplateRuntime {
  executeOnStateChangeCode: (getter: GetterType, setter: SetterType, reason: StateChangeRunReason) => void;
  executeOnBeforeGetState: () => void;

  processProviderData: (data: Ids7ProviderData) => void;
  getCurrentProviderData: ()=>Ids7ProviderDataResult[];

  registerRuntimeComponent: (compId: string, context: ComponentRenderContext) => boolean;
  registerRuntimeComponentInfo: (compId: string, sourceId: string, parentId: string) => void; // Needed when loading state back from IDS7 to ensure that runtime information about components is properly restored 
  unregisterRuntimeComponent: (compId: string) => boolean;
  clearRuntimeComponents: () => void;

  getAllRuntimeIds(): string[];
  getOEhrInfos(): {id: string, ehrId: string}[];
  getRuntimeInfoById(compId: string): TemplateRuntimeInfo | null;
  getComponentById(compId: string): ParsedComponent<any> | null;
  getRenderContextByComponentId(compId: string): ComponentRenderContext | null;
  getComponentBySourceId(compSrcId: string): ParsedComponent<any> | null;

  setParentContext(templateContext: TemplateContext): void;
  setNewScriptRunnerGetter(scriptRunnerGetter: ScriptRunnerGetter | null): void;
}

export interface IComponentStateStore {
  useReactState: (componentId: string) => ComponentStoreComponentSelectInfo;

  getStoreValue: GetterType;
  setStoreUserInputValue: (componentId: string, propName: string, value: any, runScripts: boolean)=>void;
  compositeUpdate: (operations: CompositeActionItem[], runScripts: boolean)=>void;
  copyComponentValues: (items: CopyItem[])=>void;
  deleteComponentValues: (items: DeleteItem[])=>void;
  runPostProcessingScripts: (components: StateChangeSimpleComponentInfo[], operation?: StateChangeReasonAllType)=>void;

  getState: ()=>ComponentStoreState;
  replaceState: (state: ComponentStoreState)=>void;
  clearState: (runScripts: boolean)=>void;
  unload?: ()=>void;

  setParentContext(templateContext: TemplateContext): void;
}

// Component runtime render context
export interface ComponentRenderContext {
  theme: ColorTheme;

  currentParsedComponent: ParsedComponent<any>;
  prefix?: string;
  prefixBase?: string;

  siblingCount?: number;
  siblingIndex?: number;
  rowContext?: boolean;
  listCount?: number;
  listIndex?: number;
  parentComponentId?: string;
}

// Script run context
export interface ScriptRunnerContext {
  get: GetterType;
  set: SetterType;
  extensions: ScriptRunnerExtesionMethods;
  templateDefaults: TemplateDefaults;
  langCode: string | undefined;
  componentExists: (compId: string) => boolean;
  _scriptHelperMethods_: { [name: string]: any };
}

export interface ScriptEventHandler {
  OnDataProviderRetrieve: (getter: GetterType, setter: SetterType, data: Ids7ProviderDataResult[])=>void;
  OnStateChange: (getter: GetterType, setter: SetterType, extensionMethods: ScriptRunnerExtesionMethods)=>void;
  OnStateChangeInlineCode: (getter: GetterType, setter: SetterType, extensionMethods: ScriptRunnerExtesionMethods, isReason: (reason: string, type?: StateChangeReasonType) => boolean, calculators: CalculationInfo[])=>void;
  OnBeforeGetState: (getter: GetterType, setter: SetterType, extensionMethods: ScriptRunnerExtesionMethods)=>void;
}
export type ScriptRunnerGetter = (context: ScriptRunnerContext)=>ScriptEventHandler;

export interface CalculationInfo {
  id: string;
  name: string;
  prefix: string;
  propToSet: string;
  propRead: string;
  setAsUserInput: boolean;
  customEvaluate?: (prefix: string, expressionRunner: (prefix: string)=>any)=>any;
}

export interface ScriptRunnerExtesionMethods {
  getReasonName: () => string[], // temporarily mirrors old functionality, consider removal unless actually needed
  getReason: () => StateChangeRunReason,
  isReason: (reason: string, type?: StateChangeReasonType) => boolean,
  getAllIds: () => string[],
  getOEhrInfos: () => {id: string, ehrId: string}[];
  getProviderData: () => Ids7ProviderDataResult[]
}

// Runtime information
export type TemplateRuntimeInfo = { 
  componentSourceId: string;
  componentParentId: string | undefined;

  component?: ParsedComponent<any>, 
  context?: ComponentRenderContext 
};


// State store information when stored/retrieved from IDS7
export type ComponentStoreState = { [componentId: string]: ComponentStoreStateComponentInfo };
export type ComponentStoreStateComponentInfo = { 
  user?: ComponentStatePropsInfo, 
  script?: ComponentStatePropsInfo, 
  c?: string, 
  p?: string 
};

// State store information when retrieved by React component
export interface ComponentStoreComponentSelectInfo {
  userInputProps: ComponentStatePropsInfo;
  calculatedProps: ComponentStatePropsInfo;
}
export interface ComponentStatePropsInfo {
  [propName: string] : any;
}

// State store modification interfaces
export type GetterType = (id: string, propName: string | null, getNonDisplaying?: boolean, getLayered?: boolean) => any;
export type SetterType = (id: string, propName: string, value: any, userInput?: boolean | 'force')=>void;
export type UserInputSetterType = (id: string, propName: string, value: any)=>void;
export type ExplicitSetterType = (id: string, propName: string, value: any, userInput: boolean | 'force')=>void;

export type CopyItem = { sourceCompId: string; destCompId: string; };
export type DeleteItem = { compId: string };
export interface NewUserInput { componentId: string; propName: string; value: any }
export type CompositeActionItem = (NewUserInput | CopyItem[] | DeleteItem[]);

// State change reason
export type StateChangeReasonAllType = 'init' | 'clear' | 'replace';
export type StateChangeReasonType = 'setUserInput' | 'delete' | 'copy' | 'componentAdded' | 'event';
export interface BasicStateChangeReason {
  operation?: StateChangeReasonAllType;
  components: StateChangeComponentInfo[]
}
export interface StateChangeReason extends BasicStateChangeReason {
  changeNumber: number;
}
export interface StateChangeRunReason extends StateChangeReason {
  iteration: number;
}
export interface StateChangeComponentInfo {
  type: StateChangeReasonType;
  name: string;
  componentId?: string;
  propName?: string;
  prefix?: string;
}
export interface StateChangeSimpleComponentInfo {
  type: StateChangeReasonType
  compId: string
  propName: string | null | undefined
}

// Runtime error
export interface IRuntimeError {
  message: string;
  componentId?: string;
  propName?: string;

  componentSpecPath?: string;
  yamlLine?: number;
  yamlChar?: number
}

// Data provider structure
export type ProviderDataRefinerFunction = (x: Ids7ProviderDataResult[])=>Ids7ProviderDataResult[];
export interface Ids7ProviderData {
  results: Ids7ProviderDataResult[];
}
export interface Ids7ProviderDataResult {
  providerName: string;
  providerData: { properties: { [propName: string] : any }, reportData?: Ids7ProviderReportDataItem /* only populated if a data-forwarding provider is used*/ }[] /* Array because if using forwarding provider we retreive one full item per report. */
}
export interface Ids7ProviderReportDataItem {
  authorizationDate: string | null;
  preliminaryAuthorizationDate: string | null;
  reportDate: string;
  templateGuid: string;
  templateId: string;
  templateName: string;
}

// Theme
export type ColorTheme = 'light' | 'dark';

// Component structure
export type SrMandatoryType = 'alert' | 'prohibit' | 'none';
export type SrtMandatoryFieldsDisplay = 'display' | 'prohibit-only' | 'no-display';

export interface TemplateSpec {
  Metadata: TemplateMetadata;
  ReportOutput: any[];
  Components: Component[];
  DataSources: string[];
  ExportsImages: boolean;
}

export interface TemplateMetadata {
  title: string;
  outputIdentifier: string;
  description: string;
  lang: string;
  versionMajor: number | null;
  versionMinor: number | null;
  mandatoryFields: SrtMandatoryFieldsDisplay;
  explicitDataSources: string[];
  canExportImages: boolean | null;
  forwardProviders: string[];
  defaults: TemplateDefaults;
  reportVersion?: string;
}

export type Component = {
  [Key in string]: any;
};

export type ComponentLookup = { [id: string]: ParsedComponent<any>[] };

export interface TemplateField {
  Name: string;
  GlobalName: string;
  Type: number;
  Path: string[];
  Destination: string;
  ClearDestinationOnEmpty: boolean;
}

// Component pased structured
export interface ParsedTemplateSpec {
  SourceSpec: TemplateSpec,
  Metadata: TemplateMetadata;
  DataSources: string[];
  Root: ParsedComponent<any>;
  ComponentLookup: ComponentLookup;
  ParseErrors: ParseError[];
  AllExpressions: ParsedComponentExpression[];
  TemplateFields: TemplateField[];
  ReturnRtf: boolean;
}

export interface ParseError {
  error: string;
  path?: string;
  componentId?: string;
  propName?: string;
  // if line and ch is not set the line presented in error is based on path
  line?: number;
  ch?: number; // char number in line
}

export interface ParsedComponentExpression {
  componentId: string;
  componentPropName: string;
  componentPropNameRead: string;
  expression: string;
  expressionName: string;
  setAsUserInput: boolean;
  customEvaluate?: (props: any, prefix: string, expressionRunner: (prefix: string)=>any, get: GetterType, setter: SetterType, templateContext: TemplateContext)=>any;
}

export interface ParsedComponent<TProps extends SrComponentPropsBase> {
  id: string;
  oEhrId?: string;
  componentKey: string;
  componentName: string;
  componentPath: string;
  component: SrComponent<TProps>;
  args: { [propName: string]: any };
  expressions: ParsedComponentExpression[];
  expresionByPropName: { [propName: string]: ParsedComponentExpression };
  children: ParsedComponent<any>[];
  parent: ParsedComponent<any> | null;
  sourceId: string | null;
  dynamicScopeIds: string[];
  parseErrors: ParseError[];
}

// Guideline for script vs. user set:
// Set in script scope for properties where the value is based on itself and manipulated by the user
// I.e. a correcte value that might be corrected differently if something changes such as min/max values
// Then we will re-eval the corrected value based upon the original input instead of a possibly already corrected value given other
// inputs that are no longer valid.
// Typically this applies to value, but not that you typically doesn't want to interfer with values that can be provided by specs either
// such as hidden. Also note that script is the top layer and expressions may override 

export type OnStateChangeRunner<TProps> = (
  props: TProps,
  setter: ExplicitSetterType,
  getter: GetterType,
  context: ComponentRenderContext,
  templateContext: TemplateContext,
  runContext: ScriptRunnerContext,
)=>void;

//
// SR Component interface
//

export type SimpleComponentInfo = { type: StateChangeReasonType, compId: string, propName: string | null | undefined };
export type SetUserInputType = (componentId: string, propName: string, value: any, runScripts?: boolean) => void;

// Accessor for functions available to use in a component. Note that if you component renders children, they should get a reference copy to your object of the functions
export interface SrtComponentFunctions {
  // Call this from the component on any change in your controlls
  setUserInput: SetUserInputType;

  // Delete the full state of a component, as it never existed. (Including yaml props)
  deleteComponentValues: (componentIds: string[]) => void;

  // Moves all props from source to destination, except id.
  copyComponents: (ids: { sourceCompId: string, destCompId: string }[]) => void;

  // Same as above but delayed, especially useful for a stream of setUserInput which then can be issued with runScripts: false.
  runScripts: (timeoutMs: number, components: SimpleComponentInfo[]) => void;

  // Get all the ids of components currently in the form
  getAllIds: () => string[];

  // Multiple state updates in one call yeilding one script run and one re-render
  compositeUpdate: (operations: CompositeActionItem[], runScripts?: boolean) => void;
}

// This part defines how the component should look in the yaml, etc. Not used in the rendering/running part.
export interface SrtComponentPreRenderInfo {
  key: string; // I.e. 'Row' or 'Option' identifying the component in the yaml
  template: string | (() => Promise<string>); // YAML code for the toolbox generation
  toolboxName: string;
  schema: any; // A schema for validating the component structure. 
}

// Specifies functions that the Components should implement. I.e. a render function to render the actual component.
export interface SrComponent<TProps> extends  SrtComponentPreRenderInfo {
  // Function to do actual render in preview view. KeyNumber is a number generated by the SrTemplate renderer
  render: (props: TProps, renderContext: ComponentRenderContext, templateContext: TemplateContext, functions: SrtComponentFunctions) => React.ReactNode; 

  // Optional: An initializer called at render time to intialize additional component props not definied in the yaml.
  // For instance to set value to falje for a boolean component such as checkbox
  // should return the key and values that will be received down as a prop to the component at render if nothing else defined
  getInitValues?: () => { [propName:string]:any }; 
    
  // Optional: A custom children method, called on yaml parse time (for instance, in case the component has multiple 
  // children properties such as tabs). If not defined assuming property "components" is children
  getChildren?: (args: TProps) => { propPath: string, children: any[] }[]; 
    
  // Optional: Get any code section property parts and how to handle them. Called on yaml parse time, when traversing the tree.
  // Results which property to set and the code section, i.e. "Summary: {x.label} = {x.value}, {y.label} ...",
  // with optional condtions to show.
  // TODO: What is a code section?
  getCodeSections?: (componentYamlArgs: TProps) => CodeSectionsResponse<TProps>[];

  // Optional: Whether children are in a dynamic scope or not
  dynamicChildScope?: boolean;

  expressionPreprocessor?: (propName: string, expression: string, props: TProps)=>string;

  // Optionally handle custom events on global state change - registered to ScriptEngine and called from there
  // NOTE: Calc-layer is clear before each run, then executed multiple times as a change may lead to additional change etc.
  //       As of such its important to always fully validate and set a value, ie. if (expression) set(x) may lead 
  //       to expression being true in the first iteration but false in the second leading to incorrectly setting x when done.
  //       Set using undefined as value to negate any prior set.
  //       Also not that set is performed in script scope unless userScope is explicity set (last param) to setter
  onStateChangeRunner?: OnStateChangeRunner<TProps>;

  // Optionally handle value reformatting when a data source value being set
  normalizeDataSourceValue?: (value: any, propName: string)=>any;

  // Optional method to handle unhide of a node
  dynamicUnhide?: (node: HTMLElement, id: string, context: string, setter: UserInputSetterType, getter: GetterType)=>void;
}

export interface CodeSectionPart {
  value: any;
  type: 'text' | 'value' | 'label' | 'spacer';
  filter: boolean | null;
  components?: string[];
}

// One CodeSectionResponse per property value to set into the component. (I.e. also one per function generated)
export interface CodeSectionsResponse<TProps> {
  // Helps to indicate which property is causing errors
  propNameRead?: string;

  // The property to set in the props manager when executing the code
  propNameToSet: string,
    
  // The sections - can be many i.e. for summary you have multiple '-text' sections that should be combined to the same incoming prop
  codeSections: CodeSection[];

  // If defined, called when the expression is evaluated (render time). Instead of the default action set(id, propNameToSet, result) 
  // where result is just the resulting code section string, this method is executed so that the raw result (CodeSectionPart[]) 
  // can be obtained and manipulated before the final result is set.
  customEvaluate?: CustomerEvaluateMethod<TProps>;

  executeInParentDyanmicContext?: boolean;
}

export type CustomerEvaluateMethod<TProps> = (renderTimeProps: TProps, prefix: string, expressionRunner: (prefix: string)=>CodeSectionPart[], getter: GetterType, setter: SetterType, templateContext: TemplateContext)=>any;

// A code section is a value containing many calculations inlined in a string. I.e. "My value is {x.value} and it is {x>100?'large':'small"}
export interface CodeSection {
  // The textline containing code blocks '{}' found, with code that should be inserted to the generated code among other inline code // TODO, then this means the getCodeSections should not be called when running id7 mode, double check that its not
  // I.e. this string can produce multiple code blocks for i.e. "Test {value} and {value2}"
  // NOTE: can be a single code section or multiple
  sectionText: string;

  // Optional expression returning a boolean value (default: true). If evaluating to false, the propNameToSet will be empty
  // NOTE: can be a single condition matching the corresponding code section, or multiple (paired by index)
  condition?: string,

  // If there are multiple code sections this string is appended before each section, effectively acting as a separtor, 
  // optoinal property and by default new line is used ("\n")
  // NOTE: When used (multiple items) the list item will also have a leading spacer as it's appended before each item. 
  //       The spacer will only be added if the condition evaluates to true
  codeSectionSpacerString?: string;

  // Whether to apply smart filter or not
  smartFilter?: boolean;

  outputSection?: string;
}

// All components should inherit this as their props
export interface SrComponentPropsBase {
  id: string,
  display?: boolean,
  label?: string;
  oEhrId?: string;
  inherritedLabel: string | null; // inherited from parent if no label is set
}

export interface SrContainerComponentPropsBase extends SrComponentPropsBase {
  components: Component[];
}

export class WhenEmptyOptions {
  static clear = 'clear';

  static noUpdate = 'noUpdate';
}

export interface FreeFieldSpec {
  id: string;
  whenEmpty: string;
}

export interface SrValueComponentPropsBase extends SrComponentPropsBase {
  dataarg?: string;
  hidden?: boolean;
  disabled?: boolean;
  mandatory?: SrMandatoryType;
  name?: string;
  worklistAttribute?: string;
  freeField?: FreeFieldSpec;
  value: any;
}

export interface DataproviderServerType {
  DbId: number;
  Id: string;
  Uid: string;
  Version: number;
  MockData?: string;
}

export interface ExportedProviderGroup {
  Name: string;
  DataproviderIds: string[];
}

export interface ExportedProvider {
  Id: string;
  Name: string;
  ExternalProviderUid?: string;
  ExternalProviderVersion?: number;
  ExampleDataJson?: string;
}

export interface ExportedProvidersFile {
  Providers: ExportedProvider[];
  ProviderGroups: ExportedProviderGroup[];
}

export interface ReportTextPart {
  text: string;
  styling: ReportTextFormatting;
  tableHtml?: string;
  sectionId?: string;
}

export interface ReportTextFormatting {
  preformat: boolean;
  strong: boolean;
  emphasis: boolean;
  small: boolean;
  h1: boolean;
  h2: boolean;
  h3: boolean;
  tableContext: 'none' | 'table' | 'thead' | 'tbody' | 'tr' | 'th' | 'td';
  tableWidth?: 'xs' | 'sm' | 'md' | 'lg';
  minColumnWidth?: number;
  columnWidths?: (number | null | undefined)[];
}
