import React, { useEffect, useState } from 'react';
import { ReactComponentContainerProps } from './SrtComponent';
import { ReportTextPart, SrComponent } from '../BasicTypes';
import { getReport, getTextOutputItems, Summary, SummaryComp, SummaryProps } from './Summary';


const mapItemsIntoSections = (items: ReportTextPart[]) => {
  let mappedItems: {[sectionId: string]: ReportTextPart[]} | null = null;
  for (let item of items) {
    if (item.sectionId == null) {
      continue;
    }
    if (mappedItems == null) {
      mappedItems = {};
    }
    if (!(item.sectionId in mappedItems)) {
      mappedItems[item.sectionId] = [item];
    } else {
      mappedItems[item.sectionId].push(item);
    }
  }
  return mappedItems;
}

const isSameItem = (item1: ReportTextPart, item2: ReportTextPart) => {
  return item1.text === item2.text && item1.tableHtml === item2.tableHtml &&
    JSON.stringify(item1.styling) === JSON.stringify(item2.styling);
}

export const ReportOutputComp: React.FC<ReactComponentContainerProps<SummaryProps>> = (container) => {
  const props = container.props;
  const items = getTextOutputItems(props.calculatedReport);
  const [initialItems, setInitialItems] = useState<{[sectionId: string]: ReportTextPart[]} | null>(null);

  const templateDefaults = container.templateContext.defaults;
  const preFont = templateDefaults.defaultPreFont;
  const normalFontFamily = templateDefaults.reportNormalFont;
  const normalFontSize = templateDefaults.reportNormalTextSize != null
    ? getAbsolutFontSize(templateDefaults.reportNormalTextSize) 
    : null;

         
  const reportElements = getReport(items, {
    pre: preFont,
    normalFont: normalFontFamily,
    normalSize: normalFontSize,
    h1Size:  getAbsolutFontSize(templateDefaults.reportH1TextSize, normalFontSize),
    h2Size:  getAbsolutFontSize(templateDefaults.reportH2TextSize, normalFontSize),
    h3Size:  getAbsolutFontSize(templateDefaults.reportH3TextSize, normalFontSize),
    smallSize:  getAbsolutFontSize(templateDefaults.reportSmallTextSize, normalFontSize),
  });

  useEffect(() => {
    console.debug('setCustomStateExecuted was changed, new value: ', container.templateContext.setCustomStateExecuted);
    setInitialItems(mapItemsIntoSections(items));
  }, [container.templateContext.setCustomStateExecuted])

  let changedSections: string[] | null = null;
  if (initialItems != null) {
    const currentItems = mapItemsIntoSections(items);
    if (currentItems != null) {
      changedSections = [];
      for (let [sectionName, sectionEntries] of Object.entries(currentItems)) {
        if (sectionEntries.length != initialItems[sectionName].length) {
          changedSections.push(sectionName);
          continue;
        }
        for (let i = 0; i < sectionEntries.length; i++) {
          if (!isSameItem(sectionEntries[i], initialItems[sectionName][i])) {
            changedSections.push(sectionName);
            break;
          }
        }
      }
    }
  }

  const changedSectionsComps = changedSections == null ? 
    <input type="text" data-all-sections-changed={true} value="all sections changed" hidden={true} readOnly={true} />
  : changedSections.map(sectionId => 
    <input type="text" data-changed-section={true} value={sectionId} hidden={true} readOnly={true} />
  );

  return (
    <>
      <div id={props.id}>{reportElements}</div>
      <SummaryComp props={{ ...props, display: false }} context={container.context} templateContext={container.templateContext} functions={container.functions}/>
      {changedSectionsComps}
    </>);
};

export const ReportOutput : SrComponent<SummaryProps> = {
  getCodeSections: Summary.getCodeSections,
  key: 'Report',
  render: (props, context, templateContext, functions) => <ReportOutputComp props={props} context={context} templateContext={templateContext} functions={functions} />,
  // Should not be used for report
  template: '', 
  toolboxName: 'ReportOutput',
  schema: {
        'type': 'array',
        'items': {
          'type': 'object',
          'required': ['text'],
          'properties': {
            'text': { 'type': 'string', 'description': 'Text to go into the report. If multiple they will be appended.' },
            'if': { 'type': 'string', 'description': 'If the text should be included or not' },
            'separator': { 'type': 'string', 'description': 'The separator to have between this text item and the next. Defaults to newline' },
            'sectionId': { 'type': 'string', 'description': 'The id of the IDS7 report section to output this content to. NOTE: Requires IDS7 26.2 or later'}
          },
        },
        // "minItems": 0,
        'description': 'The text to go to the report. Multiple children are allowed and each child can be conditioned and/or written to different IDS7 report sections', 
      },
};

// IDS7 can only handle absolut font-size as an integer and thus we will need to convert any font size given in percent
function getAbsolutFontSize(font: string | number, parentFontSize?: number | null): number | null {
  if (typeof font === 'number') return font;

  const containerSize = parentFontSize ?? 9;
  if (String(font).endsWith('%')) {
    const rel = parseInt(String(font));
    if (isNaN(rel) || !isFinite(rel) || rel <= 0 || rel > 100) return null;
    return containerSize * rel  / 100;
  }
  const val = parseInt(String(font));
  return !isNaN(val) && isFinite(val) && val > 0 && val < 200 ? val : null;
}
