/* eslint-disable no-redeclare */
/* eslint-disable @typescript-eslint/no-redeclare */
import { SrComponent } from '../BasicTypes';
import { Row } from './SectraRow';
import { Text, SectraDate } from './SectraInput';
import { Number } from './SectraInputNumber';
import { Options } from './SectraOptions';
import { ListSection } from './SectraListSection';
import { StaticText } from './SectraStaticText';
import { SectraPainter } from './SectraPainter';
import { SectraDropdown } from './SectraDropdown';
import { ComponentGroup, SectraSection } from './Section';
import { SectraCheckbox } from './Checkbox';
import { TextArea } from './TextArea';
import { Header, HR, RowBreak, VSpace } from './SectraStructure';
import { Summary } from './Summary';
import { SectraTab } from './SectraTabs';
import { SectraGraph } from './SectraGraph';
import { CheckboxList } from './CheckboxList';
import { Button } from './SectraButton';
import { Help } from './Help';
import { HtmlBox } from './SectraHtmlBox';
import { AutoComplete } from './SectraAutocomplete';

// Dictionary for string -> SrComponent of the implemented components/toolbox-options.
// i.e. { {"SingleRowInputInt", SectraInputRowInt}, {"Options", SectraOptionsRow} }
export const Components: { [dictKey: string]: SrComponent<any> } = Object.freeze({
  [SectraPainter.key]: SectraPainter,
  [SectraDropdown.key]: SectraDropdown,
  [Header.key]: Header,
  [ComponentGroup.key]: ComponentGroup,
  [SectraSection.key]: SectraSection,
  [TextArea.key]: TextArea,
  [Row.key]: Row,
  [Text.key]: Text,
  [Number.key]: Number,
  [SectraDate.key]: SectraDate,
  [Options.key]: Options,
  [ListSection.key]: ListSection,
  [SectraCheckbox.key]: SectraCheckbox,
  [HR.key]: HR,
  [StaticText.key]: StaticText,
  [Summary.key]: Summary,
  [SectraTab.key]: SectraTab,
  [VSpace.key]: VSpace,
  [SectraGraph.key]: SectraGraph,
  [RowBreak.key]: RowBreak,
  [CheckboxList.key]: CheckboxList,
  [Button.key]: Button,
  [Help.key]: Help,
  [HtmlBox.key]: HtmlBox,
  [AutoComplete.key]: AutoComplete
  // [Table.key]: Table, not ready, dont include
});

// The instances of the Components are created by JsonToTeact.ts:getSrComponents