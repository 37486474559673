import { ISectraOptionsValue, SectraOptionsHelper } from "../SectraOptionsBase";
import { ISingleOptionValue } from "./SectraOptionBase";

export enum SortOption {
    BeginsWith,
    ContainsSequence,
}

export const getSortOptionDescription = (sortOption: SortOption): string => {
  switch(sortOption) {
    case SortOption.BeginsWith:
      return "Matches options that start with the same words as the search input starts with. Longest match first. ";
    case SortOption.ContainsSequence:
      return "Matches options that contains a sequence of the search input. Longest match first."
  }
  return "";
}

export const autocompleteMatch = (input: string, options: ISectraOptionsValue[], selectedValues: ISingleOptionValue[], sortOptions: SortOption[], includeSelectedValues: boolean): ISingleOptionValue[] => {
  let optionTexts = SectraOptionsHelper.getTexts(options).map(x => x.toLocaleLowerCase());
  let optionValues = SectraOptionsHelper.getValues(options, true);
  const allMatchesIndicies = search(input, optionTexts, null, []);
  input = input.toLocaleLowerCase();
  let foundIndicies: number[] = []
  for (let sortOption of sortOptions) {
    foundIndicies = foundIndicies.concat(search(input, optionTexts, sortOption, foundIndicies));
  }
  foundIndicies = foundIndicies.concat(allMatchesIndicies.filter(x => foundIndicies.indexOf(x) === -1));
  let matches: ISingleOptionValue[] = foundIndicies.map(x => optionValues[x]);
  if (!includeSelectedValues) {
    matches = matches.filter(x => selectedValues.indexOf(x) === -1);
  }
  return matches;
};

const search = (input: string, optionValues: string[], sortOption: SortOption | null, alreadyMatchedIndicies: number[]): number[] => {
  const matchFunc = {[SortOption.BeginsWith]: beginsWith, [SortOption.ContainsSequence]: containsSequence}
  let matches: number[] = [];
  if (sortOption == null) {
    matches = anyMatch(input, optionValues);
  } else {
    matches = matchFunc[sortOption](input, optionValues);
  }
  return matches.filter(x => alreadyMatchedIndicies.indexOf(x) === -1);
}

const beginsWith = (input: string, optionValues: string[]): number[] => {
  return sequenceMatcher(input, optionValues, 1, (opt, matchPart) => opt.startsWith(matchPart), true);
}

const containsSequence = (input: string, optionValues: string[]): number[] => {
  return sequenceMatcher(input, optionValues, 2, (opt, matchPart) => opt.indexOf(matchPart) !== -1, false);
}

const sequenceMatcher = (input: string, optionValues: string[], minParts: number, seqFunc: (opt: string, matchPart: string) => boolean, matchStartWordsOnly: boolean): number[] => {
  const inputParts = input.split(' ');
  const foundOptionIndices: number[] = [];
  if (inputParts.length < minParts) return [];
  for (let nrParts = inputParts.length; nrParts >= minParts; nrParts--) {
    const matchParts = [inputParts.reduce((prev, curr, i) => i < nrParts ? prev.length > 0 ? prev + " " + curr : curr : prev, "")];
    if (!matchStartWordsOnly) {
      for (let startIndex = 0; startIndex + nrParts <= inputParts.length; startIndex++) {
        matchParts.push(inputParts.reduce((prev, curr, i) => i >= startIndex && i < startIndex + nrParts ? prev.length > 0 ? prev + " " + curr : curr : prev, ""));
      }
    }
    for (let matchPart of matchParts) {
      for (let i = 0; i < optionValues.length; i++) {
        if (foundOptionIndices.indexOf(i) !== -1) continue;
        if (seqFunc(optionValues[i], matchPart) && optionMatches(input, optionValues[i])) {
          foundOptionIndices.push(i);
        }
      }
    }
  }
  return foundOptionIndices;
}

const anyMatch = (input: string, optionValues: string[]): number[] => {
  const retval: number[] = [];
  for (let i = 0; i < optionValues.length; i++) {
    if (optionMatches(input, optionValues[i])) {
      retval.push(i);
    }
  }
  return retval;
}

const optionMatches = (input: string, option: string): boolean => {
  const matches : (RegExpMatchArray | null)[] = [];
  input = input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  let regExps = input.split(' ').sort((a, b) => b.length - a.length).map(x => new RegExp(x.toLocaleLowerCase()));
  for (let regExp of regExps) {
    const match = option.match(regExp);
    matches.push(match);
    if (match != null) {
      option = option.replace(regExp.source, '');
    }
  }
  if (matches.every(x => x != null)) {
    return true;
  }
  return false;
}