import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { runDataProviderFetch } from '../DataproviderFetch';
import { AppColorMode } from '../FormsNavigation';
import { IServerCommunication } from '../ServerCommunication';
import { ParseYaml } from '../YamlToJson';
import { TemplateContext, TemplateSpec } from './BasicTypes';
import { generateCompleteScript } from './SrtComponent/ScriptRunnerHelper';
import { ParseTemplateSpec } from './SrtTemplateSpec';
import { TemplateBaseContainer } from './TemplateBaseContainer';
import { initalizeTemplateContext } from './TemplateContextHelper';

import './Preview.scss';
import { Alert } from 'react-bootstrap';

export interface PreviewProps {
  server: IServerCommunication;
  colorMode: AppColorMode;
  onColorModeChange: (x: AppColorMode) => void;
}

export const Preview: React.FC<PreviewProps> = ({ server, colorMode, onColorModeChange }) => {
    type PreviewParams = {
      previewId?: string;
      previewVersion?: string;
    };
    const { previewId, previewVersion } = useParams<PreviewParams>();
    const [templateContext, setTemplateContext] = useState<TemplateContext | null>(null);
    const [previewLoadingError, setPreviewLoadingError] = useState<boolean>(false);

    window.clearState = () => {
      if (templateContext !== null) {
        templateContext.componentStore.clearState(true);
      }
    };

    useEffect(() => {
      async function getPreviewTemplate(id: string, version?: string) {
        try {
          const preview = await server.getPreviewTemplate(id, version ? parseInt(version) : null);
          const templatespec = ParseYaml(preview.Yaml, false);
          const reportSpec = ParseTemplateSpec((templatespec as TemplateSpec), false);
          setTemplateContext(initalizeTemplateContext(reportSpec, { fullCode: generateCompleteScript(preview.FullCode, reportSpec), prevContext: null }));
          setPreviewLoadingError(false);
        } catch {
          setPreviewLoadingError(true);
        }
      }
      if (previewId) {
        getPreviewTemplate(previewId, previewVersion);
      }
    }, [previewId, previewVersion]);

    useEffect(() => {
      if (templateContext !== null) {
        runDataProviderFetch();
      }
    }, [templateContext]);
    return (
        <>
            <div className="preview-template-container">
                <div className="preview_toolbox">
                    <button onClick={() => onColorModeChange(colorMode === 'theme-dark' ? 'theme-light' : 'theme-dark')}>Theme: {colorMode === 'theme-dark' ? 'Dark' : 'Light'}</button>
                </div>
                {previewLoadingError ?
                  <Alert bsStyle="danger">Could not load form preview, make sure the form has preview enabled and that the link is correct.</Alert>
                  : templateContext == null ?
                  <p>Loading...</p>
                    : <TemplateBaseContainer isIds7Container={false} templateContext={templateContext} />
                }
            </div>
        </>
    );
};
