import type { MenuItemsProps } from '.';

import React from 'react';
import { useLocation } from 'react-router';
import { NavItemButton } from './Common';

const GUID_REGEX = /(^([0-9A-Fa-f]{8}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{12})$)/;

export function Context({ menus }: MenuItemsProps) {
  const location = useLocation();
  const path = location.pathname.split('/');

  const isEditingSaved = path[1]?.match(GUID_REGEX);
  const isEditingNew = path[1]?.match(/new/);
  const isEditingImported = path[1]?.match(/fromFile/);

  if (isEditingNew || isEditingSaved || isEditingImported) {
    return <EditingContext menus={menus} />;
  }

  return null;
}

function EditingContext({ menus }: MenuItemsProps) {
  return <>
    {menus.map((m, i) =>
      <NavItemButton
        key={i}
        disabled={m.disabled}
        name={m.text}
        title={m.title}
        onClick={m.action}
      />,
    )}
  </>;
}