/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { KeyboardEventHandler, createRef, useCallback, useEffect, useRef, useState } from 'react';
import { ISectraOptionsValue, SectraOptionsHelper } from '../SectraOptionsBase';
import { ISingleOptionValue } from './SectraOptionBase';
import { SvgRef } from '../../Generic/SvgRef';
import { SortOption, autocompleteMatch } from './SectraAutocompleteHelper';

export interface SectraAutocompleteBaseProps {
  options: ISectraOptionsValue[];
  onInputChange: (values: ISingleOptionValue[]) => void;
  maxSearchResults: number;
  value: ISingleOptionValue[];
  id: string;
  name: string;
  allowMultiple: boolean;
  singleOptionsDeletable: boolean;
  minSearch: number;
  showSelectedValues: boolean;
  optionsMatchOrder: SortOption[];
  placeholder?: string;
  hidden?: boolean;
  disabled?: boolean;
}

export const SectraAutocompleteBase: React.FC<SectraAutocompleteBaseProps> = (props) => {
  const [searchResultValues, setSearchResultValues] = useState<ISingleOptionValue[]>([]);
  const [selectedValues, setSelectedValues] = useState<ISingleOptionValue[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [controlOpened, setControlOpened] = useState<boolean>(false);
  const [draggedIndex, setDraggedIndex] = useState<number>(-1);
  const [singlePlaceHolder, setSinglePlaceholder] = useState<string>();
  const [popRight, setPopRight] = useState<boolean>(true);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);
  const [lastOpenWasFromKeyboard, setLastOpenWasFromKeyboard] = useState<boolean>();
  const [activeOption, setActiveOption] = useState<number | null>(null);
  const dropdownRef = createRef<HTMLDivElement>();
  const dropdownListRef = createRef<HTMLDivElement>();
  const dropdownElementRefs = useRef<HTMLButtonElement[]>([]);
  const dropdownInputRef = useRef<HTMLInputElement>();
  const defaultPlaceholder = 'Select an option...';
    

  const showSearchResults = (inputValue: string) => {
    setSearchInput(inputValue);
    if (inputValue.length > 0) {
      setControlOpened(true);
    }
  };

  const addSelectedElement = (value: ISingleOptionValue) => {
    const newSelectedValues = props.allowMultiple ? [...selectedValues, value] : [value];
    props.onInputChange(newSelectedValues);
    setSearchInput('');
    setControlOpened(false);
    setFocusedOptionIndex(null);
    setActiveOption(null);
    dropdownInputRef.current?.focus();
  };

  const removeSelectedElement = (value: ISingleOptionValue) => {
    const newSelectedValues = selectedValues.filter(x => x !== value);
    props.onInputChange(newSelectedValues);
  };

  const handleDropdownBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (e.currentTarget.contains(e.relatedTarget)) {
      return;
    }
    setSearchInput('');
    setControlOpened(false);
    setFocusedOptionIndex(null);
    setActiveOption(null);
  };

  const handleEmptySearch = () => {
    if (props.minSearch !== 0) {
      return;
    }

    const newSearchResultValues = [];
    const optionValues = SectraOptionsHelper.getValues(props.options, true);
    for (let i = 0; i < props.options.length; i++) {
      if (props.showSelectedValues || selectedValues.indexOf(optionValues[i]) === -1) {
        newSearchResultValues.push(optionValues[i]);
      }
    }
    setSearchResultValues(newSearchResultValues);
  };
    
  const inputClick = () => {
    if (props.disabled) {
      return;
    }
    if (controlOpened) {
      setFocusedOptionIndex(null);
      setActiveOption(null);
    }
    setControlOpened(!controlOpened);
  };

  const setChildRef = useCallback((id, el) => {
    if (el) {
      dropdownElementRefs.current[id] = el
    } else {
      dropdownElementRefs.current = dropdownElementRefs.current.filter((x, i) => i != id);
    }
  }, []);

  useEffect(() => {
    if (activeOption == null) {
      return;
    }

    for (let i = 0; i < dropdownElementRefs.current.length; i++) {
      const elem = dropdownElementRefs.current[i];
      if (i === activeOption) {
        setFocusedOptionIndex(i);
        elem.scrollIntoView({block: 'nearest', inline: 'nearest'});
        elem.classList.add("active");
      } else {
        elem.classList.remove("active");
      }
    }
  }, [activeOption]);

  useEffect(() => {
    if(!controlOpened) {
      return;
    }
    
    const dropdownRect = dropdownRef.current?.getBoundingClientRect();
    const dropdownListRect = dropdownListRef.current?.getBoundingClientRect();
    const viewportRect = (window as any).srtcontainerRect;
    if (dropdownListRect == null || dropdownRect == null || viewportRect == null) return;

    if (viewportRect.right - dropdownRect.right < dropdownListRect.width && viewportRect.right - dropdownRect.right < dropdownRect.left - viewportRect.left) {
      setPopRight(false);
    } else {
      setPopRight(true);
    }
    if (lastOpenWasFromKeyboard) {
      setLastOpenWasFromKeyboard(false)
      if (dropdownElementRefs.current.length > 0) {
        setFocusedOptionIndex(0);
        setActiveOption(0);
      }
    }
  }, [controlOpened]);

  useEffect(() => {
    if (dropdownElementRefs.current.length > 0) {
      setFocusedOptionIndex(0);
      setActiveOption(0);
    }
  }, [searchResultValues])

  useEffect(() => {
    setSelectedValues(props.value);
  }, [props.value]);

  useEffect(() => {
    if (searchInput.length < props.minSearch) {
      return;
    }

    if (searchInput.length > 0) {
      setSearchResultValues(autocompleteMatch(searchInput, props.options, selectedValues, props.optionsMatchOrder, props.showSelectedValues));
    } else {
      handleEmptySearch();
    }
  }, [selectedValues, searchInput, props.minSearch, props.options]);

  useEffect(() => {
    setSinglePlaceholder(undefined);
    if (!props.allowMultiple && props.value.length > 1) {
      props.onInputChange([]);
    }
  }, [props.allowMultiple]);

  const handleDrop = (dropIndex: number) => {
    if (draggedIndex == -1) {
      return;
    }

    let arr = [...selectedValues];
    arr.splice(dropIndex, 0, arr.splice(draggedIndex, 1)[0]);
    props.onInputChange(arr);
  };

  const handleSingleSelect = () => {
    if (props.disabled) {
      return;
    }
    setControlOpened(true);
    if (props.value != null && props.value.length === 1) {
      setSinglePlaceholder('' + props.value[0]);
    } else {
      setSinglePlaceholder(props.placeholder ?? defaultPlaceholder);
    }
  };

  const handleSingleRemove = () => {
    setSinglePlaceholder(undefined);
    props.onInputChange([]);
  };

  const handleDropdownListKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (controlOpened && dropdownElementRefs.current.length > 0 && (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter')) {
      if (e.key === 'ArrowDown') {
          e.preventDefault();
          e.bubbles = false;
        if (focusedOptionIndex == null || focusedOptionIndex == dropdownElementRefs.current.length - 1) {
          setActiveOption(0);
        } else {
          setActiveOption(focusedOptionIndex + 1);
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.bubbles = false;
        if (focusedOptionIndex == null || focusedOptionIndex == 0) {
          setActiveOption(dropdownElementRefs.current.length - 1);
        } else {
          setActiveOption(focusedOptionIndex - 1);
        }
      } else if (e.key === 'Enter') {
        if (focusedOptionIndex != null) {
          addSelectedElement(dropdownElementRefs.current[focusedOptionIndex].innerText);
        }
      }
    }
    else if(!controlOpened && (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter' || e.key === 'Space')) {
      setLastOpenWasFromKeyboard(true);
      handleSingleSelect();
    }
  };

  // Render functions
  function SearchInfo() {
    if (props.minSearch > searchInput.length) {
      return <span className="dropdown-item info">Type {props.minSearch - searchInput.length} more letters to find results...</span>;
    }

    if (searchResultValues.length === 0) {
      return <span className="dropdown-item info">No results found...</span>;
    }

    if (searchResultValues.length > props.maxSearchResults) {
      return <span className ="dropdown-item info">More results found. Keep narrowing down the search...</span>
    }
    return <></>
  }

  function MultipleSelectedValues() {
    if (!props.allowMultiple) {
      return <></>;
    }
    return <div className="autocomplete-values" onDragOver={ev => { ev.preventDefault(); ev.dataTransfer.dropEffect = 'move'; }}>
        {selectedValues.map((selectedValue, i) =>
            <div className="autocomplete-value-row selected-item" draggable={true} onDragStart={() => setDraggedIndex(i)} onDrop={() => handleDrop(i)} key={i}>
                <span className='selected-item-text'>{selectedValue}</span>
                <button disabled={props.disabled} className="btn btn-link chevron delete" onClick={() => removeSelectedElement(selectedValue)}>
                    <SvgRef id='icon-ListDelete' className="svg-icon delete no-border" />
                </button>
            </div>,
        )}
    </div>
  }

  const vals = SectraOptionsHelper.getValues(props.options, true);
  return (
        <div key={props.id} className="autocomplete" ref={dropdownRef} hidden={props.hidden}>
            {MultipleSelectedValues()}
            <div onBlur={handleDropdownBlur} onKeyDown={handleDropdownListKey}>
                {!props.allowMultiple && props.value.length === 1 && !controlOpened ?
                    <div className="input-container">
                        <input ref={a => a != null ? dropdownInputRef.current = a : undefined} type="text" autoComplete="off" className="form-control autocomplete-single-input" onClick={() => handleSingleSelect()}
                        disabled={props.disabled} onChange={e => {setControlOpened(true); setSearchInput(e.currentTarget.value)}} value={!props.allowMultiple && props.value.length === 1 ? '' + props.value[0] : undefined} />
                        {props.singleOptionsDeletable ?
                          <span onClick={!props.disabled ? handleSingleRemove : undefined}><SvgRef id="icon-ListDelete" className="svg-icon clear no-border" /></span>
                          : <span onClick={handleSingleSelect}> <SvgRef id="icon-ListCollapse" className="svg-icon no-border" /></span>}
                    </div> 
                  :
                    <div className="input-container" onClick={inputClick}>
                        <input ref={a => a != null ? dropdownInputRef.current = a : undefined} tabIndex={controlOpened ? -1 : 0} type="text" autoComplete="off" disabled={props.disabled} style={{paddingRight: '40px'}}
                            placeholder={singlePlaceHolder ?? props.placeholder ?? defaultPlaceholder} className="form-control"
                            onChange={e => showSearchResults(e.currentTarget.value)} value={searchInput} />
                        <SvgRef id="icon-ListCollapse" className="svg-icon no-border" />
                    </div>
                }
                <div className="dropdown-items-container" style={popRight ? {left: 0} : {right: 0}} ref={dropdownListRef}>
                    {controlOpened ?
                    <>
                       {props.minSearch > searchInput.length ? undefined : searchResultValues.slice(0, props.maxSearchResults).map((x, i) =>
                            <button tabIndex={-1} className="dropdown-item" key={i} 
                              ref={el => setChildRef(i, el)} onClick={() => addSelectedElement(x)}>
                                {SectraOptionsHelper.getTexts([vals.find(y => y === x) ?? ""])[0]}
                            </button>)}
                        {SearchInfo()}
                    </>
                      : undefined}
                </div>
            </div>
        </div>
  );
};
