import { SrtComponentPreRenderInfo } from "../BasicTypes";
import { templateDefaultsSchema } from "../TemplateDefaults";
import { IDataSourceInfo } from "../Validator";
import { languages } from "./Languages";

const metadataKey = 'Metadata';
const explicitDataSourceDescription = 'The name of an explicit data source that needs to be included (note that data sources are automatically included when referred to using the dataarg property)';
const explicitDataSources = (dataSources: IDataSourceInfo[]) => {
    return {
    'type': 'array',
    'description': 'List of additional providers not referred to using dataarg properties which are needed for this form (script dependencies)',
    'items': dataSources.length > 0
        ? {
        'type': 'string',
        'enum': dataSources.map(s => s.name),
        'enumDescription': dataSources.map(s => s.description), 
        'description': explicitDataSourceDescription,
        }
        : { 
        'type': 'string',
        'description': explicitDataSourceDescription,
        },
    }
};

const langCodeList = languages.map(l => l.code);
const langCodeDesc = languages.map(l => l.name);

export const getMetadata : (dataSources: IDataSourceInfo[]) => SrtComponentPreRenderInfo = (dataSources: IDataSourceInfo[]) => {
    return {
        key: metadataKey,
        schema: {
            'type': 'object',
            'required': ['lang', 'title', 'description'],
            'properties': {
            'lang': { 'type': 'string', 'description': 'The form main language.', 'enum': langCodeList, 'enumDescription': langCodeDesc },
            'title': { 'type': 'string', 'description': 'Form name, i.e. the name of the form visible in most lists of forms' },
            'description': { 'type': 'string', 'description': 'Form description' },
            'versionMajor': { 'type': 'integer', 'description': 'Form major version' },
            'versionMinor': { 'type': 'integer', 'description': 'Form minor version' },
            'defaults': templateDefaultsSchema,
            'outputIdentifier': { 'type': 'string', 'description': "If defined, the report will output it's data as a forwarding provider, and thus enable other forms to use it" },
            'mandatoryFields': { 'type': 'string', 'enum': ['display', 'prohibit-only', 'no-display'], 'enumDescription': ['Display all empty mandatory fields at the bottom of the form (default)', 'Display only prohibitted fields (i.e. fields which cannot be skipped) at the bottom of the from', 'Do not display any mandatory fields'], 'description': 'Controlls the display of mandatory fields at the bottom of the form (default: display)' },
            'forwardProviders': {
                'type': 'array',
                'items': {
                'type': 'string',
                'description': 'The name of the forwarding data source as configured in that form (outputIdentifier)',
                },
                'description': 'Forwarding data sources as configured in their form (outputIdentifier)',
            },
            'reportVersion': { 'type': 'string', 'description': 'Report version interface', 'enum': ['v1', 'v2'], 'enumDescription': ['Supported in IDS7 versions >= 22.1, can not use tables', 'Supported in IDS7 versions >= 24.2, possible to use tables'] },
            'explicitDataSources': explicitDataSources(dataSources),
            'canExportImages': { 'type': 'boolean', 'description': 'If set to true, it will be possible to export images to IDS7. (Default: true if Painter or Graph component exists, otherwise false)'}
            },
        },
        template: '',
        toolboxName: metadataKey,
    }
};