import type { MenuItemsProps, ThemeProps, UserProps } from '.';

import React from 'react';
import { SvgSymbol } from '../Components/SvgSymbol';
import { IconSheet } from '../Resources/IconSheet';

import { NavItemLink } from './Common';
import { Brand } from './Brand';
import { Help } from './Help';
import { Admin } from './Admin';
import { Context } from './Context';
import { Profile } from './Profile';

import './Navigation.scss';


export function FormsNavigation(props: ThemeProps & MenuItemsProps & UserProps) {
  return <>
    <SvgSymbol />
    <IconSheet />
    
    <nav className="navbar-default navbar-fixed-top sectra-forms-navbar" data-highlight-type="nav">
      <ul>
        <Brand />

        <NavItemLink href="/DataProviders" name="Data providers" />

        <Help />

        <Admin {...props} />
      </ul>

      <ul>
        <Context {...props} />
        <Profile {...props} />
      </ul>
    </nav>
  </>;
}