import { applogger } from './applogger';
import { TemplateContext } from './Components/BasicTypes';
import { convertReportToRTF } from './Helpers/RTFHelper';
import { getTextOutputItems } from './Components/SrtComponent/Summary';
import './WindowIf';

/*
Defining the functions called from IDS7 in order to control input/output. Functions should be defined on window level;
 */
export class Ids7Interface {
  private templateContext: TemplateContext | null = null;

  setTemplateContext(context: TemplateContext) {
    this.templateContext = context;
    if ((context?.templateSpec?.Metadata?.reportVersion ?? 'v1') === 'v2') {
      (window as any).getReportRtf = () => convertReportToRTF(getTextOutputItems(context.componentStore.getStoreValue('#Report', 'calculatedReport')));
    }
    window.setProviderData = (data: any) => this.templateContext?.runtime.processProviderData(data);
    window.setCustomState = (data: any) => this.setCustomState(data);
    window.getCustomState = () => this.getCustomState();
    window.dataProvidersUpdated = (providerIds: string[]) => this.dataProvidersUpdated(providerIds);
    window.getProviderData = () => this.templateContext?.runtime?.getCurrentProviderData() ?? [];
  }
    
  // Called by ids7 when pressing done button in the report.
  // Returns the react state
  getCustomState(): { [compId : string]: any } {
    if (this.templateContext == null) {
      applogger.error('Cannot retrieve state without a form context');
      return {};
    }

    try {
      this.templateContext.runtime.executeOnBeforeGetState();
    } catch (e) {
      applogger.error('Error while running the on close event!');
    }

    let customState = this.templateContext.componentStore.getState();
    applogger.debug('getCustomState', customState);
    return customState;
  }

  // Called by ids7 when doing ctrl-click on the report text in non-srt mode, to enter the structured reporting again
  // Will get the object that was returned by getCustomState
  setCustomState(customState: { [compId : string]: any }) {
    if (this.templateContext == null) {
      applogger.error('Cannot set state without a form context');
      return;
    }

    applogger.debug('setCustomState', customState);
    this.templateContext.componentStore.replaceState(customState);
  }

  // Called by IDS7 when a provider has new data. Currently supported from PACS 22.2 and later
  // and only implemented for SectraLesionTracking
  dataProvidersUpdated(providerIds: string[]) {
    // This is a notification that providers have been updated. Auto-request the an update
    requestDataProviderUpdate(providerIds);
  }
}

// Request update of provider data from IDS7 (will trigger a consequent call to setProviderData)
export function requestDataProviderUpdate(providerIds?: string | string[]) {
  const getDataMethod: (providerIds: string[])=>void = getBaseContainerWindow().getData;
  if (getDataMethod == null) {
    applogger.error('Unable to fetch base container getData method and thus unable to request data provider update!');
    return;
  }

  const ids = providerIds != null 
    ? (Array.isArray(providerIds) ? providerIds : [providerIds])
    : [];

  applogger.debug('getData()', ids);
  getDataMethod(ids);
}

function getBaseContainerWindow(): any  {
  let w: Window = parent ?? window;
  return w;
} 