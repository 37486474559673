import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route, useHistory, matchPath } from 'react-router-dom';

import { ExceptionServer, GetServerCommunication, IServerCommunication, SrtSite, SrtGenTemplate, SrtTemplateFolder, UserResponse, UserRole } from './ServerCommunication';
import { saveToFile } from './FileSaveLoad';
import { MainTemplateList } from './MainTemplateList/MainTemplateList';
import { useCookieState, useRefState } from './Components/ReactExt';
import { applogger } from './applogger';
import { Dataproviders } from './Components/DataproviderAdmin/Dataproviders';
import { DataproviderServerType } from './Components/BasicTypes';
import { routeToPath } from './Helpers/NavigationHelper';
import { Preview } from './Components/Preview';
import { UserAdmin } from './Components/UserAdmin/UserAdmin';
import { SectraAdmin } from './Components/UserAdmin/SectraAdmin';
import { TemplateAdminFrame } from './TemplateAdminFrame';
import { About } from './Help/About';
import { ReleaseNotes } from './Help/ReleaseNotes';
import { UsersGuide } from './Help/UsersGuide';
import { Login } from './LoginPage/Login';
import { AppColorMode, FormsNavigation } from './FormsNavigation';

export const Main: React.FC = () => {
  const [appColorMode, setAppColorMode] = useCookieState('theme-dark' as AppColorMode, 'dt');
  const [providers, setProviders] = useState<DataproviderServerType[] | null>(null);
  const [currentTemplateFolder, setCurrentTemplateFolder] = useState<SrtTemplateFolder>();
  const [showingLatestTemplateVersion, setShowingLatestTemplateVersion] = useState<boolean>(true);
  const [user, setUser] = useState<UserResponse | null>(null);
  const [serverIsUpgrading, setServerIsUpgrading] = useState<boolean>(false);
  const [wasLogin, setWasLogin] = useState<boolean>(false);
  document.documentElement.className = appColorMode;

  const [sites, setSitesInternal] = useState<SrtSite[]>(()=>[]);
  function setSites(newSites: SrtSite[]) {
    const allSiteFolders = newSites.filter(site => site.IsCurrent)?.[0]?.Folders ?? [];
    if (currentTemplateFolder == null || allSiteFolders.every(f => f.Id !== currentTemplateFolder.Id)) {
      // we need to update to a folder which matches the site, lets find the root folder
      const newCurrentFolder = allSiteFolders.find(x => x.ParentId == null) ?? allSiteFolders[0];
      setCurrentTemplateFolder(newCurrentFolder);
    }

    setSitesInternal(newSites);
  }

  const [server, setServerInternal] = useState<IServerCommunication>(new ExceptionServer());
  function setServer(serv: IServerCommunication) {
    applogger.debug('setServer');
    setServerInternal(serv);
  }

  // Handle site and getting new templates via a refresh counter
  // Note: refreshCnt is passed to MainTemplateList which fetches the templates
  //       for the current site
  const [refreshCnt, setRefreshCnt] = useState(0);
  function requestRefreshedListFromServer() {
    applogger.debug('setRefreshCount');
    setRefreshCnt(refreshCnt + 1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      server.getIsServerUpgrading().then(x => setServerIsUpgrading(x));
    }, 30 * 1000);
    return () => clearInterval(interval);
  });
    
  useEffect(()=>{
    GetServerCommunication().then((serv: IServerCommunication)=> { 
      setServer(serv);
      serv.getCsrfToken().then(x => localStorage.setItem('csrf', x));
      try {
        serv.fetchUser().then(u => {
          if (user == null && u != null) {
            setWasLogin(true);
          } else {
            setWasLogin(false);
          }
          setUser(u);
        }).catch(ex => {
          applogger.info(ex); setUser(null);
        });
      } catch (ex) {
        setUser(null);
      }
    });
  }, [refreshCnt]);

  useEffect(() => {
    if (user != null && user.Role >= UserRole.User) {
      try {
        server.getSites().then(setSites);
      } catch (ex) {
        applogger.error('Could not fetch sites', ex);
      }
      try {
        server.getProviders().then(setProviders);
      } catch (ex) {
        applogger.error('Could not fetch providers', ex);
      }
    }
      
  }, [refreshCnt, user]);

  // keep track of whether the template is unsaved or not, and prevent leaving page without prompting a warning
  // using a ref+state here so that we booth can trigger reload but still can refer to the var by reference so that the 
  // setup below with on leave methods will refer to the "correct" boolean value and not the false value at init time
  const [isUnsaved, setUnsaved] = useRefState(false);
  // setup some routing
  const history = useHistory();
  useEffect(()=>{
    const navigateAwayWithoutSave = 'There are unsaved changes, are you really sure you would like to leave without saving changes?';
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      const state: any = history.location.state;
      const skipSavePrompt = state?.skipSavePrompt ?? false;
      if (isUnsaved.current && !skipSavePrompt) {
        e.preventDefault();
        if (e) e.returnValue = navigateAwayWithoutSave; // Legacy method for cross browser support
        return navigateAwayWithoutSave; // Legacy method for cross browser support
      }
    };

    history.block(() => {
      const state: any = history.location.state;
      const skipSavePrompt = state?.skipSavePrompt ?? false;
      if (isUnsaved.current && !skipSavePrompt && matchPath(routeToPath(history.location.pathname), routeToPath('/:id/:version?'))) {
        if (!confirm(navigateAwayWithoutSave)) {
          return false;
        }
        setUnsaved(false);
      }
    });

    history.listen((location: any) => {
      // If we navigate to main page, lets refresh template list
      if (location.pathname == routeToPath('/')) {
        requestRefreshedListFromServer();
      }
    });
  }, []);


  const latestSpecToSave = useRef<SrtGenTemplate>();

  const saveChanges = async () => {
    let context = latestSpecToSave.current;
    if (!context) {
      alert('No context');
      return null;
    }
    let response = await server.save(context as SrtGenTemplate);     
    let templateId = response.Id;
    if (templateId) {
      setUnsaved(false);
      history.push(routeToPath('/' + templateId + (response.Version != null ? '/' + response.Version : '')), { skipSavePrompt: true });
    }

    return response;
  };

  const saveChangesItem = {
    text: 'Save changes', 
    disabled: !isUnsaved.current, 
    title: isUnsaved.current ? 'Save current form (new save version generated)' : 'No changes to save', 
    action:  async () => {
      try {
        if (showingLatestTemplateVersion || confirm('Are you sure you want to save the current changes that are not based on the latest version of the form?')) {
          await saveChanges();
        }
      } catch (e) {
        alert('Save failed');
      }
    },
  };

  const exportAsFileItem = {
    text: 'Export as file', 
    title: 'Export current form as a file to be shared with others', 
    disabled: false, 
    action: () => {
      let context = latestSpecToSave.current;
      if (context) {
        saveToFile(context);
      }
    },
  };

  const menus = [saveChangesItem, exportAsFileItem];
    
  if (!server || !server.isReady()) {
    return <div>
            <div className="MainContent" >
                <div className="container" style={{ marginTop:10 }}>
                    Loading ...
                </div>
            </div>
        </div>;
  }

  const onFolderUpdate = async (parentId: number, folderName: string, id: number | null) => {
    await server.updateFolder(parentId, folderName, id);
    requestRefreshedListFromServer();
  };

  const onFolderDelete = async (id: number) => {
    await server.deleteFolder(id);
    requestRefreshedListFromServer();
  };

  // Get the current site and site folders (currently not rendered)
  const currentSite = sites.filter(a => a.IsCurrent)[0];
  const folders = currentSite?.Folders ?? [];

  const previewRoute = 
      <Route exact path={routeToPath('/preview/:previewId/:previewVersion?')}>
          <Preview server={server} colorMode={appColorMode} onColorModeChange={(x: AppColorMode)=> setAppColorMode(x)} />
      </Route>;

  if (user == null) {
    return <Switch>
      {previewRoute}
      <Route >
        <Login server={server} />
      </Route>
    </Switch>;
  }

  return (
    <>
      <div style={{ marginTop: '60px' }}>
        <FormsNavigation 
          colorMode={appColorMode} 
          onColorModeChange={(x: AppColorMode)=> setAppColorMode(x)} 
          menus={menus} 
          user={user} 
        />

      {user.Role >= UserRole.User ?
        <>
        <Switch>
            
            <Route exact path={routeToPath('/')}>
            
                {currentTemplateFolder &&
                    <MainTemplateList onFolderDelete={onFolderDelete} onFolderSelect={x => setCurrentTemplateFolder(x)} selectedFolder={currentTemplateFolder}
                        onFolderUpdate={onFolderUpdate} onRefresh={requestRefreshedListFromServer} currentFolders={folders} onNavigate={(path) => history.push(routeToPath('/' + path))}
                        server={server} refreshCnt={refreshCnt} appColorMode={appColorMode} user={user} wasLogin={wasLogin} onGuideShow={() => setWasLogin(false)} />}
            </Route>
            <Route exact path={routeToPath('/DataProviders')}>
                <>
                    <Dataproviders server={server} dataproviders={providers} onProviderSaved={() => server.getProviders().then(setProviders)} />
                </>
            </Route>
            {previewRoute}
            <Route exact path={routeToPath('/UserAdmin')}>
                <UserAdmin isSectraAdmin={false} server={server} />
            </Route>
            <Route exact path={routeToPath('/SectraAdmin')}>
                <SectraAdmin server={server} />
            </Route>
            <Route exact path={routeToPath('/help')}>
              <ReleaseNotes />
            </Route>
            <Route exact path={routeToPath('/help/about')}>
              <About />
            </Route>
            <Route exact path={routeToPath('/help/usersguide')}>
              <UsersGuide />
            </Route>
            <Route exact path={routeToPath('/:id/:version?')}>
                {providers ?
                    <TemplateAdminFrame currentTemplateFolder={currentTemplateFolder} server={server} providerNames={providers?.map(x => x.Id)} setNewSrtGenTemplateObject={(template: SrtGenTemplate)=>{
                      latestSpecToSave.current = template;
                    } 
                    }
                    setUnsaved={(val)=>setTimeout(()=>setUnsaved(val), 0)}
                    saveChanges={saveChanges}
                    appColorMode={appColorMode}
                    onShowingLatestVersionChange={isShowing => setShowingLatestTemplateVersion(isShowing)}
                    showingLatestVersion={showingLatestTemplateVersion}
                    serverIsUpgrading={serverIsUpgrading}
                    isUnsaved={isUnsaved.current} />
                  : <p>Loading...</p>
                }
            </Route>
        </Switch>
        </>
        :
        <>
          <p>You are logged in, but do not have permissions to do anything yet.</p>
          <p>A Sectra Forms site admin can give you access to their site. If they asks for your Id,
            press the button to copy your Id to the clipboard.</p>
          <button className="btn btn-default" onClick={() => navigator.clipboard.writeText(user.Guid)}>Copy Id</button>
        </>
      }</div>
    </>
  );
};