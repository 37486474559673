/* eslint-disable @typescript-eslint/naming-convention */
import { Components } from './SrtComponent/Components';
import Ajv, { AdditionalPropertiesParams, EnumParams } from 'ajv';
import { languages } from './SrtComponent/Languages';
import { applogger } from '../applogger';
import { TemplateSpec, ParseError } from './BasicTypes';
import { getMetadata } from './SrtComponent/Metadata';
import { ReportOutput } from './SrtComponent/ReportOutput';

const componentSchemas = Object.keys(Components).map(k => Components[k]).map(x => x.schema);
const langCodeList = languages.map(l => l.code);
const langCodeDesc = languages.map(l => l.name);

export interface IValidationContext {
  dataSources: IDataSourceInfo[]
}

export interface IDataSourceInfo {
  name: string;
  description?: string;
}

function getSchema(context: IValidationContext) {
  return {
    '$schema': 'http://json-schema.org/draft-07/schema#',
    '$id': 'http://example.com/srt.builder.schema.json',
    'title': 'Sectra Forms specification schema',
    'type': 'object',
    'required': ['Metadata', 'Components', 'ReportOutput'],
    'additionalProperties': false,
    'properties': {
      'Metadata': getMetadata(context.dataSources).schema,
      'ReportOutput': ReportOutput.schema,
      'Components': {
        'type': 'array',
        'description': 'Any component.',
        'items': {
          '$ref' : 'srt.builder.component.json#/definitions/component',
        },
      },
    },
  };
}

const schemaDefinitions = {
  '$id': 'http://example.com/srt.builder.component.json',
  'definitions': {
    'component': { 'anyOf': componentSchemas },
  },
};

export function getSchemaInfo(context: IValidationContext): ISchema {
  return {
    Schema: getSchema(context), 
    Definitions: schemaDefinitions,
  };
}

export interface ISchema {
  Schema: any;
  Definitions: any;
}

export const validateSpec = (data: TemplateSpec, context: IValidationContext, errors?: ParseError[]) => {
  try {
    let validator = getValidator(context);
    let valid = validator(data);
    if (!valid) {
      if (errors != null && validator.errors != null) {
        let filter: { path: string, item: ParseError, keyword: RegExp }[] = [];
        let vErrors = validator.errors
          .sort((a, b) => a.dataPath.length < b.dataPath.length ? 1 : (a.dataPath.length > b.dataPath.length ? -1 : 0))
          .map(e => { 
            const path = e.dataPath;
            let item = { error: e.message ?? '', path: path, propName: e.propertyName, keyword: e.keyword };

            if (e.keyword === 'additionalProperties' && (e.params as AdditionalPropertiesParams).additionalProperty) {
              item.propName = (e.params as AdditionalPropertiesParams).additionalProperty;
              item.error = "Property '" + item.propName + "' is not allowed (" + item.error + ')';

              // Temporary special handling of data sources for backwards compatability
              if (item.propName == 'DataSources') {
                item.error = "\n'DataSources'-specification is no longer needed. All data sources referred to using ‘dataarg’-properties will automatically be included. Forwarding providers can be specified under the ‘Metadata’-section (forwardProviders), as well as data source that is explicitly needed because of usage from scripts alone (explicitDataSources). \n\nPlease also note that any external data sources first needs to be recorded as a known builder data provider in the data providers admin (add as an ‘External dataprovider’).\n";
              }

              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'enum') {
              item.error += ' (' + ((e.params as EnumParams).allowedValues ?? []).join(', ') + ')';
              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'type') {
              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'required' && !path.endsWith(']')) {
              filter.push({ path: path, item: item, keyword: /required|anyOf/ });
            } else if (e.keyword === 'required' && e.schemaPath === '#/anyOf/0/required') {
              item.error = 'Unrecognized reference';
              if (filter.every(i => i.path.length < path.length)) {
                filter.push({ path: path + ' ', item: item, keyword: /required|anyOf/ });
              }
            }

            return item;
          });

        if (filter.length) {
          filter.forEach(f => {
            vErrors = vErrors.filter(i => {
              return !(f.item !== i && (i.path.length < f.path.length || i.path.length == f.path.length && i.keyword === 'anyOf') && f.path.startsWith(i.path) && f.keyword.test(i.keyword));
            });
          });
        }

        // applogger.debug(validator.errors, filter);
        errors.push(...vErrors);
      }

      return false;
    }
  } catch (e) {
    if (errors != null) {
      errors.push({ error: `Unknown validation error: ${e}` });
    }

    applogger.error('Cannot validate spec', e);
    return false;
  }

  return true;
};

//
// Pre-compile the schema and reuse, saves around 40ms per validation
//
var _ajvValidator: Ajv.ValidateFunction;
var _ajvValidatorContextKey: string;

function getValidator(context: IValidationContext): Ajv.ValidateFunction {
  const contextKey = JSON.stringify(context);
  if (_ajvValidator && contextKey === _ajvValidatorContextKey) return _ajvValidator;

  applogger.debug('Compiling new schema validator');
  const ajv = new Ajv();
  ajv.addSchema(schemaDefinitions);
    
  _ajvValidator = ajv.compile(getSchema(context));
  _ajvValidatorContextKey = contextKey;
  return _ajvValidator;
}
