import type { UserProps } from '.';

import React from 'react';
import { NavItemLink } from './Common';
import { UserRole } from '../ServerCommunication';

export function Admin({ user }: UserProps) {
  switch (user.Role) {
    case UserRole.Admin:
      return <NavItemLink href="/UserAdmin"  name="Administer users"/>;
    case UserRole.SectraAdmin:
      return <NavItemLink href="/SectraAdmin" name="Sectra admin" />;
    default:
      return null;
  }
}