import React from 'react';
import { Grid } from 'react-bootstrap';


export const ReleaseNotes: React.FC = () => {
  return (
    <Grid>
      <h1>Release Notes</h1>
      <h3>Version 1.4</h3>
      <ul>
        <li>Autocomplete fixes
          <ul>
            <li>Now persists state in edit-mode</li>
            <li>Now pops over content</li>
            <li>Improved keyboard navigation</li>
            <li>Order options</li>
            <li>Styling fixes</li>
          </ul>
        </li>
        <li>Support for IDS7 sections</li>
        <li>Shared templates are now sorted in alphabetical order</li>
        <li>New navbar style</li>
        <li>Renamed SectraSection component to ComponentGroup to avoid mixup with IDS7 Sections (SectraSection will still work for backwards compatibility)</li>
        <li>Set forms to only export images if Painter or Graph components are used or if explicitly setting the Metadata-property canExportImages to true</li>
        <li>Fixed bug where reportVersion: v2 forms would not produce any output if no component with id reportSummary were present</li>
      </ul>

      <h3>Version 1.3</h3>
      <ul>
        <li>New component: Autocomplete</li>
        <li>Fixed bug which could make IDS7 26.1.3 and later to throw away manually entered data into Summary-components when entering edit-mode</li>
        <li>New top-level global Sectra folder containing Forms that can help you learning</li>
        <li>Added links to video-tutorials in Help and in User Guide</li>
        <li>Fixed cosmetic issue witch made smallest possible dropdowns to show first pixel of first letter</li>
      </ul>
      <h3>Version 1.2</h3>
      <ul>
        <li>Support for importing openEHR templates</li>
        <li>Fixed bug which made it possible to create data providers with identical names.</li>
        <li>Fixed bug where it was not possible to read free-fields through the dataarg attribute.</li>
        <li>Fixed bug where the selected user role in site-admin options did not get highlighted.</li>
      </ul>

      <h3>Version 1.1</h3>
      <ul>
        <li>Fixed bug where pre/mono tags resulted in broken report</li>
        <li>Fixed bug where stored Painter image did not get annotations made when storeImageResult was false</li>
        <li>Increased typing performance in Summary-component</li>
        <li>Added link to users guide in Help-tab</li>
        <li>Changed styling of toolbar buttons</li>
        <li>Minor user guide update</li>
        <li>Security fixes</li>
      </ul>

      <h3>Version 1.0</h3>
      <ul>
        <li>New front-page</li>
        <li>Show warning when multiple users are editing the same form</li>
        <li>Add checkbox to enable/disable template preview</li>
        <li>Fixed issue where scaled sector-map could retrieve points on wrong coordinates when editing a already created structured report</li>
        <li>Fixed issue with fill marker, causing the fill-annotation to be misplaced in generated image</li>
      </ul>

      <h3>Version 0.1</h3>
      <ul>
        <li>Sectra Forms is now released in the cloud</li>
        <li>Sign in with my.sectra.com</li>
        <li>Site concept</li>
        <li>In-app user guide</li>
        <li>Security fixes</li>
      </ul>
    </Grid>
  );
};