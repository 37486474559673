import React from 'react';
import { Link } from 'react-router-dom';
import { SvgRef } from '../Components/Generic/SvgRef';

export function Brand() {
  return <li title="Administration overview">
    <Link to='/'>
      <div className="brand">
        <div className="logo-container">
          <SvgRef className="sectra-logo" id="spx-ico-brand-logo-sectra" />
        </div>
        <span id="logo-text">Forms</span>
      </div>
    </Link>
  </li>;
}