import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { applogger } from '../../applogger';
import { isGenericDataProvider, isInternalProvider } from '../../Helpers/DataproviderHelper';
import { IServerCommunication } from '../../ServerCommunication';
import { DataproviderServerType, ExportedProvidersFile } from '../BasicTypes';
import { GenericTable, GenericTableRowData } from '../Generic/GenericTable';
import { Dataprovider } from './Dataprovider';

import './Dataproviders.scss';

export interface DataprovidersProps {
  server: IServerCommunication;
  dataproviders: DataproviderServerType[] | null;
  onProviderSaved: () => void;
}

export const Dataproviders: React.FC<DataprovidersProps> = (props) => {
  const [selectedProvider, setSelectedProvider] = useState<DataproviderServerType | null>(null);
  const [providersToAdd, setProvidersToAdd] = useState<DataproviderServerType[]>([]);
  const [selectedProvidersToAdd, setSelectedProvidersToAdd] = useState<boolean[]>([]);
  const [showDuplicateWarning, setShowDuplicateWarning] = useState<boolean>(false);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (providersToAdd.length) {
      const newSelectedProvidersToAdd = providersToAdd.map(x => !props.dataproviders?.some(p => x.Id === p.Id));
      setSelectedProvidersToAdd(newSelectedProvidersToAdd);
      setShowDuplicateWarning(newSelectedProvidersToAdd.some(x => !x));
    } else {
      setShowDuplicateWarning(false);
      setSelectedProvidersToAdd([]);
    }
  }, [providersToAdd]);

  async function saveProvider(saveData: DataproviderServerType) {
    setSelectedProvider(null);
    try {
      await props.server.saveProvider(saveData);
      props.onProviderSaved();
    } catch (e) {
      applogger.error(e);
      alert("Save Failed");
    }
  }

  function cancelProvider() {
    setSelectedProvider(null);
  }

  async function deleteProvider(providerIndex: number) {
    if (props.dataproviders) {
      const provider = props.dataproviders[providerIndex];
      if (isInternalProvider(provider) || isGenericDataProvider(provider)) {
        alert('Internal providers and GenericDataProvider cannot be deleted.');
      } else if (confirm('Are you sure you want to delete the dataprovider ' + provider.Id + '?')) {
        try {
          await props.server.deleteProvider(provider.DbId);
          props.onProviderSaved();
        } catch (e) {
          applogger.error(e);
        }
      }
    }
  }

  async function importProviders() {
    try {
      const promises = [];
      for (let i = 0; i < providersToAdd.length; i++) {
        if (selectedProvidersToAdd[i]) {
          promises.push(props.server.saveProvider(providersToAdd[i]));
        }
      }
      await Promise.all(promises);
      props.onProviderSaved();
      setProvidersToAdd([]);
      if (fileInput?.current) {
        fileInput.current.value = '';
      }
    } catch (e) {
      applogger.error(e);
    }
  }

  function getDbId(id: string) {
    const providersWithSameId = props.dataproviders?.filter(x => x.Id === id);
    const dbId = providersWithSameId && providersWithSameId.length > 0 ? providersWithSameId[0].DbId : 0;
    return dbId;
  }

  function updateProvidersToAdd(files: FileList | null) {
    if (files !== null) {
      const newProviders: DataproviderServerType[] = [];
      let readFiles = 0;
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileResult: ExportedProvidersFile = JSON.parse((e?.target?.result) as string);
        console.debug('Read providers file: ', fileResult);
        for (let group of fileResult.ProviderGroups) {
          const providersInGroup = fileResult.Providers.filter(x => group.DataproviderIds.indexOf(x.Id) !== -1);
          const providersMockData = providersInGroup.map(x => x.Name + ': ' + x?.ExampleDataJson ?? '');
          const id = group.Name;
          newProviders.push({ DbId: getDbId(id), Id: id, Uid: id, Version: 1, MockData: `{${providersMockData}}` });
        }
        for (let provider of fileResult.Providers) {
          const id = provider.Name;
          newProviders.push({
            DbId: getDbId(id), Id: provider.Name, Uid: provider.ExternalProviderUid ?? provider.Name,
            Version: provider.ExternalProviderVersion ?? 1, MockData: provider.ExampleDataJson ?? '',
          });
        }
      };
      reader.onloadend = () => {
        readFiles++;
        if (readFiles === files.length) {
          console.debug('Setting providers to add: ', newProviders);
          setProvidersToAdd(newProviders);
        }
      };
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file !== null) {
          reader.readAsText(file);
        }
      }
    }
  }

  function onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    updateProvidersToAdd(event?.dataTransfer?.files);
  }

  function dragOverHandler(event: any) {
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
    event.stopPropagation();
  }

  const dataProviderRowData: GenericTableRowData[] | undefined = props.dataproviders
    ?.map(x => ({ values: [x.Id, x.Uid, '' + x.Version] } as GenericTableRowData));

  const importProviderRowData: GenericTableRowData[] | undefined = providersToAdd
    ?.map((x, i) => ({
      values: [x.Id, x.Uid, '' + x.Version],
      className: selectedProvidersToAdd?.[i] ? 'selected' : undefined,
      iconId: selectedProvidersToAdd?.[i] ? 'icon-Checkmark' : undefined,
    } as GenericTableRowData));

  return (
        <div className="MainContent DataProviders" onDrop={onDrop} onDragEnter={dragOverHandler} onDragLeave={() => false} onDragOver={dragOverHandler}>
            <div className="container" style={{ marginTop: 10 }}>
                {selectedProvider === null ?
                    <>
                        <GenericTable 
                            emptyMessage="No dataproviders, create one!" 
                            headers={['Id', 'Uid', 'Version']}
                            tableRows={dataProviderRowData}
                            onClick={i => setSelectedProvider(props?.dataproviders ? props.dataproviders[i] : null)} 
                            onDelete={deleteProvider} 
                        />
                        <Button bsStyle="primary" onClick={() => setSelectedProvider({ Id: '', Uid: '', Version: 1, DbId: 0 })}>
                            Add
                        </Button>

                        <hr />
                        <h3>Import providers </h3>
                        <Alert bsStyle="warning" style={showDuplicateWarning ? {} : { display: 'none' }}>
                            <p>One or more provider ids already exist. Select them manually before adding to overwrite.</p>
                        </Alert>
                        <div>
                            <GenericTable 
                                emptyMessage="Use the load button or drag and drop provider packages (.srtbp) here" 
                                headers={['Id', 'Uid', 'Version']}
                                tableRows={importProviderRowData}
                                onClick={i => setSelectedProvidersToAdd(selectedProvidersToAdd?.map((x, ix) => ix === i ? !x : x))} 
                            />
                        </div>
                        <input type="file" ref={fileInput} accept=".srtbp" onChange={e => updateProvidersToAdd(e.currentTarget.files)} style={{ display: 'none' }} />
                        <Button bsStyle="primary" onClick={() => importProviders()} disabled={!providersToAdd.length}>Add selected</Button>
                        <button className="btn btn-secondary" style={{ marginLeft: '1rem' }} onClick={() => fileInput?.current?.click()} >Load</button>
                    </>
                  : <Dataprovider dataprovider={selectedProvider} onCancel={cancelProvider} onSave={saveProvider} />
                }
            </div>
        </div>
  );
};
