import React from 'react';
import { NavItemLink } from './Common';

export function Help() {
  return <li data-highlight-name="Help">
    <span>Help</span>
    <ul className="dropdown">
      <NavItemLink href="/help" name="Release Notes" icon="icon-Advertisement-dark"/>
      <NavItemLink href="/help/usersguide" name="Users Guide" icon="icon-Help-dark"/>
      <NavItemLink href="/help/about" name="About" icon="icon-About-dark"/>
    </ul>
  </li>;
}