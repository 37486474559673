import React, { useContext, useEffect, useState } from 'react';
import { inDemoMode, SrtGenBuild, SrtTemplateDtoExtended } from '../ServerCommunication';
import { TemplateVersions } from '../Components/TemplateVersions';
import { routeToPath } from '../Helpers/NavigationHelper';
import { TemplateField } from '../Components/BasicTypes';
import { CommunicationContext, CommunicationContextType } from './CommunicationContext';

interface Ids7PackerProps extends SrtTemplateDtoExtended {
  templateFields: TemplateField[];
  reportVersion: number;
  templateId: string;
  templateVersion?: number;
  outputsToSections: boolean;
}

export const Ids7Packager: React.FC<Ids7PackerProps> = (props) => {
  const communicationContext = useContext(CommunicationContext) as CommunicationContextType;
  const [loading, setLoading] = useState<boolean>(false);
  const [build, setBuild] = useState<SrtGenBuild | null | undefined>(props.templateVersion == null ? null : undefined);

  useEffect(() => {
    if (props.templateVersion) {
      communicationContext.getBuildInfo(props.templateId, props.templateVersion).then(b => {
        setBuild(b);
      });
    }
  }, [props.templateVersion]);

  const isUnsaved = communicationContext.isUnsaved || props.templateId == null || props.templateVersion == null;

  async function ensureSaveAndGetTemplateId(){
    let templateId: string | null | undefined = props.templateId;
    let templateVersion: number | null | undefined = props.templateVersion;

    if (isUnsaved) {
      if (!confirm('You cannot build an IDS7 template without saving first, do you wish to save now?')) {
        return { templateId: null, templateVersion: null };
      }
      const response = await communicationContext.saveChanges();
      templateId = response?.Id;
      templateVersion = response?.Version;
    }

    return { templateId, templateVersion };
  }
    
  const runBuildTemplate = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
        
    let { templateId, templateVersion } = await ensureSaveAndGetTemplateId();
    if (templateId == null || templateVersion == null){
      return;
    }
    setLoading(true);
    await communicationContext.buildIds7Package(templateId, templateVersion, props.JsCode, props.templateFields, props.reportVersion, props.metadata);
    let bld = await communicationContext.getBuildInfo(templateId, templateVersion);
    setBuild(bld);
    setLoading(false);
  };

  const onCurrentVersionDeleted = async () => {
    const templateVersions = await communicationContext.getTemplateVersionsById(props.templateId);
    if (templateVersions.length) {
      communicationContext.pushHistory(routeToPath('/' + templateVersions[0].Id + '/' + templateVersions[0].Version ?? '1'), { skipSavePrompt: true });
    } else {
      communicationContext.pushHistory(routeToPath('/'), { skipSavePrompt: true });
    }
  };

  const requiredIds7Version = props.outputsToSections ? '26.2' : props.reportVersion >= 2 ? '24.2' : props.templateFields.some(x => !!x.Destination) ? '23.1' : '22.1';
  const isProdMode = process.env.NODE_ENV === 'production';
  const alreadyBuilt = build != null && build.TemplateId === props.templateId && build.TemplateVersion == props.templateVersion;

  return (
    <div style={{ margin: '0 5px' }}>
        {!inDemoMode
          ? <>
                <h5>Build IDS7 form </h5>
                <p>This form will require an IDS7 version of {requiredIds7Version} or higher.</p>
                <button className={`btn btn-default btn-primary ${loading ? 'in-progress' : ''}${!isProdMode ? 'alert-danger' : ''}`} onClick={e => runBuildTemplate(e)}>{!isProdMode ? '[Debug mode] ' : ''}{isUnsaved || !alreadyBuilt ? 'Create new build' : 'Download existing build'}{isUnsaved ? ' (Save required)' : null}</button>
                { build != null && !isUnsaved ? <div>(this version was built at {new Date(build.Created).toLocaleString()})</div> : null}
            </>
          : null
        }
        <hr />
        <h5>Required data sources</h5>
        <p>
            { props.dataProviders.length > 0 ? <>{props.dataProviders.join(', ')}</> : <em>None</em> }
        </p>
        <hr />
            <TemplateVersions
                header={<h5 style={{ marginTop: '28px' }}>All versions</h5>}
                currentTemplateId={props.templateId}
                currentVersionHasBuild={build != null}
                templateVersion={props.templateVersion}
                deleteTemplateVersions={communicationContext.deleteTemplateVersions}
                getTemplateVersionsById={communicationContext.getTemplateVersionsById}
                pushHistory={communicationContext.pushHistory}
                onCurrentTemplateVersionDeleted={onCurrentVersionDeleted} />
    </div>
  );
};
