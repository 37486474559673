import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import './Viewer.scss';
import { isAutoAddedComponent } from '../SrtTemplateSpec';
import { Ids7Packager } from '../../FramedComponents/Ids7Packager';
import { HelpPage } from '../../HelpPage';
import { FormStateViewer } from './FormStateViewer';
import { FormDataViewer } from './FormDataViewer';
import { TemplateBaseContainer } from '../TemplateBaseContainer';
import { ErrorHandler } from '../../ErrorHandler';
import { PreviewToolbar } from './PreviewToolbar';
import { usePrevious } from '../ReactExt';
import { getCurrentTheme } from '../SrtComponent/SrtComponent';
import { applogger } from '../../applogger';
import { ParseError, TemplateContext, TemplateField } from '../BasicTypes';
import { clearRuntimeError } from '../SrtComponent/ScriptRunnerHelper';
import { TemplateSharer } from '../../FramedComponents/TemplateSharer';

/* 
The viewer is the rightmost component showing the output of your Yaml code - i.e. as ui, json or code
*/

interface ViewerProps {
  reportVersion: number,
  templateFields: TemplateField[],
  templateContext: TemplateContext | null;
  templateId: string;
  templateVersion?: number;
  fullScriptCode: string;
  parseErrors?: ParseError[] | null;
  yaml: string;
  focusSpec: () => void;
}

export const Viewer: React.FC<ViewerProps> = (props) => {
  const [ids7FormState, setIds7FormState] = useState('');
  const [theme, setTheme] = useState(getCurrentTheme());

  let reloadFormState = () => {
    // simulate what ids7 would do
    let ids7Data = window.getCustomState();

    // format for ouput and store if changed
    let ids7DataStr = JSON.stringify(ids7Data, null, 2);
    if (ids7DataStr !== ids7FormState) {
      setIds7FormState(ids7DataStr);
    }
  };

  const prevYaml = usePrevious(props.yaml, props.yaml);
  const prevScript = usePrevious(props.fullScriptCode, props.fullScriptCode);
  if (prevYaml !== props.yaml || props.fullScriptCode != prevScript) {
    clearRuntimeError();
  }

  useEffect(() => {
    const lookup = props.templateContext?.templateSpec?.ComponentLookup;
    if (lookup != null) {
      // get spec from lookup
      var deletedIds = props.templateContext?.runtime.getAllRuntimeIds()
        .map(id => ({ componentId: id, exists: lookup[props.templateContext?.runtime.getComponentById(id)?.id ?? ''] != null }))
        .filter(x => !x.exists && !isAutoAddedComponent(x.componentId))
        .map(x => x.componentId) ?? [];
            
      if (deletedIds.length) {
        applogger.debug('the following components has been deleted from the solution: ', deletedIds);
        deletedIds.forEach(k => {
          props.templateContext?.runtime.unregisterRuntimeComponent(k);
        });
      }
    }
  }, [props.yaml]);

  return (
        <div className="Viewer">
            <Tabs animation={false} defaultActiveKey="Preview" id="tabs" onClick={reloadFormState}>
                <Tab eventKey="Preview" title="Preview">
                    <div className={'tab-page preview'}>
                        <ErrorHandler parseErrors={props.parseErrors ?? []} templateContext={props.templateContext}/>
                        <div className={'preview-content theme-' + theme}>
                            <PreviewToolbar templateContext={props.templateContext} />
                            {props.templateContext != null
                              ? <TemplateBaseContainer isIds7Container={false} templateContext={props.templateContext} onThemeUpdate={newTheme => {setTheme(newTheme);}} focusSpec={props.focusSpec} /> 
                              : null }
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="FormData" title="Form data">
                    <div className={'tab-page theme-' + theme}>
                        <FormDataViewer />
                    </div>
                </Tab>
                <Tab eventKey="FormState" title="Form state">
                    <div className={'tab-page theme-' + theme}>
                    <FormStateViewer ids7FormData={ids7FormState} onNewStateLoaded={reloadFormState} />
                    </div>
                </Tab>
                <Tab eventKey="Build" title="Build">
                    <div className={'tab-page theme-' + theme}>
                        {props.templateContext != null
                          ? <Ids7Packager
                                templateId={props.templateId}
                                templateVersion={props.templateVersion}
                                reportVersion={props.reportVersion}
                                templateFields={props.templateFields}
                                dataProviders={props.templateContext.templateSpec.DataSources}
                                metadata={props.templateContext.templateSpec.Metadata}
                                outputsToSections={props.templateContext.templateSpec.SourceSpec.ReportOutput.some(x => x.sectionId != null)}
                                JsonSpec={JSON.stringify(props.templateContext.templateSpec.SourceSpec)}
                                Yaml={props.yaml}
                                JsCode={props.fullScriptCode} />
                          : null}
                    </div>
                </Tab>
                <Tab eventKey="Share" title="Share">
                  <div className={'tab-page theme-' + theme}>
                    {props.templateContext != null
                      ? <TemplateSharer templateId={props.templateId} templateVersion={props.templateVersion} />
                      : null}
                  </div>
                </Tab>
                <Tab eventKey="Help" title="Help">
                    <div className={'tab-page theme-' + theme}>
                        <HelpPage />
                    </div>
                </Tab>
            </Tabs>
        </div>
  );
};
