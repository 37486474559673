import type { ThemeProps, UserProps } from '.';

import React from 'react';
import { NavItemButton, NavItemContent } from './Common';
import { SvgRef } from '../Components/Generic/SvgRef';

export function Profile(props: ThemeProps & UserProps) {
  return <li className="profile" data-highlight-name="Profile">
    <span>
      <SvgRef id="icon-Me-dark"/>
    </span>
    <ul className="dropdown left">
      <User {...props} />
      <ThemeSwitch {...props} />
      <SignOut />
    </ul>
  </li>;
}
  
function User({ user }: UserProps) {
  if (user.FamilyName == null || user.GivenName == null || user.FamilyName.length <= 0 || user.GivenName.length <= 0) {
    return null;
  }
  return <li className="name">
    <span>{user.GivenName} {user.FamilyName}</span>
  </li>;
}

const darkIcon = 'icon-DisplayThemeDark-dark';
const lightIcon = 'icon-DisplayThemeLight-dark';

function ThemeSwitch({ colorMode, onColorModeChange }: ThemeProps) {
  function switchTheme() {
    onColorModeChange(colorMode === 'theme-dark' ? 'theme-light' : 'theme-dark');
  }
  
  const icon = colorMode === 'theme-dark' ? darkIcon : lightIcon;
  return <NavItemButton icon={icon} name="Change theme" onClick={switchTheme} />;
}
  
function SignOut() {
  return <li>
    <a href='/MicrosoftIdentity/Account/SignOut'>
      <NavItemContent icon="icon-LogOff-dark" name="Log off" />
    </a>
  </li>;
}
  