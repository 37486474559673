import { ReduxStateStore } from './ReduxStateStore';
import { TemplateContext, ParsedTemplateSpec, ITemplateRuntime } from './BasicTypes';
import { setupTemplateDefaults } from './TemplateDefaults';
import { TemplateRuntime } from './TemplateRuntime';
import { defaultEmptyEventHandler, getScriptRunnerGetterFromCodeEval, getScriptRunnerGetterFromWindow } from './SrtComponent/ScriptRunnerHelper';
import { applogger } from '../applogger';

// The optional edit mode info holds data used when in edit-mode
// Previous context is supplied to retain the runtime and component value store between spec updates
export interface EditModeInformation {
  fullCode: string;
  prevContext: TemplateContext | null;
}

export function initalizeTemplateContext(spec: ParsedTemplateSpec, editMode?: EditModeInformation): TemplateContext {
  const langCode = spec.Metadata?.lang;
  const templateDefaults = setupTemplateDefaults(spec.Metadata?.defaults, langCode);
  const windowScriptRunner = getScriptRunnerGetterFromWindow();
  const scriptRunnerGetter = windowScriptRunner ?? getScriptRunnerGetterFromCodeEval(editMode?.fullCode ?? `${defaultEmptyEventHandler};return EventHandler;`);
  if (scriptRunnerGetter == null && editMode?.fullCode == null) {
    applogger.error('Unable to retrieve script runner!');
  }

  // Retain old runtime if in edit mode, else create a new one
  let runtime: ITemplateRuntime;
  if (editMode?.prevContext?.runtime != null) {
    runtime = editMode?.prevContext?.runtime;

    // don't update script running if broken due to eval error
    if (scriptRunnerGetter != null || editMode.fullCode == null) {
      runtime.setNewScriptRunnerGetter(scriptRunnerGetter);
    }
  } else {
    runtime = new TemplateRuntime(scriptRunnerGetter);
  }

  const templateContext: TemplateContext = {
    inBuildMode: editMode != null,
    langCode: langCode,

    templateSpec: spec,
    defaults: templateDefaults,

    runtime: runtime,
    componentStore: editMode?.prevContext?.componentStore ?? new ReduxStateStore(),

    setCustomStateExecuted: false,
  };

  templateContext.runtime.setParentContext(templateContext);
  templateContext.componentStore.setParentContext(templateContext);

  return templateContext;
}
