import React, { useEffect, useState, useMemo } from 'react';
import { applogger } from '../applogger';
import { Ids7ProviderData, TemplateField, TemplateMetadata } from '../Components/BasicTypes';
import { setProviderData } from '../DataproviderFetch';
import { AppColorMode } from '../FormsNavigation';
import { SrtGenBuild, SrtGenTemplate, SrtSite, SrtTemplateFolder } from '../ServerCommunication';
import { CommunicationContext } from './CommunicationContext';
import { sendAndForget, sendAndWaitForResponse } from './CommunicationHelper';
import { IBuildIds7PackageParams, IDeleteTemplateVersionsParams, IGetBuildInfoParams, IInitializeParams, IInterFrameCom, IPushHistoryParams, ISetSuggestedTemplate, ITemplatePreviewEnabledParams, ITemplateShareParams } from './IInterFrameCom';
import TemplateAdmin from './TemplateAdmin';
import { SvgSymbol } from '../Components/SvgSymbol';

export const FramedTemplateAdmin: React.FC = () => {
  const [appColorMode, setAppColorMode] = useState<AppColorMode>('theme-dark');
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [providerNames, setProviderNames] = useState<string[]>([]);
  const [suggestedTemplateUpdate, setSuggestedTemplateUpdate] = useState<ISetSuggestedTemplate>();
  const [currentTemplateFolder, setCurrentTemplateFolder] = useState<SrtTemplateFolder>();
  const [messageSource, setMessageSource] = useState<WindowProxy>();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('message', eventListener);
    sendAndForget({ type: 'IframeMessagingReady', data: null } as IInterFrameCom<null>, parent);
    return () => window.removeEventListener('message', eventListener);
  }, []);

  async function saveChanges(): Promise<SrtGenTemplate | null> {
    return sendAndWaitForResponse<void, SrtGenTemplate | null>({ type: 'SaveChanges' } as IInterFrameCom<void>, messageSource);
  }

  function setNewSrtGenTemplateObject(template: SrtGenTemplate) {
    sendAndForget({ type: 'SetNewSrtGenTemplate', data: template } as IInterFrameCom<SrtGenTemplate>, messageSource);
  }

  function setUnsaved(val: boolean) {
    if (suggestedTemplateUpdate?.templateId !== 'fromFile') {
      sendAndForget({ type: 'SetUnsaved', data: val } as IInterFrameCom<boolean>, messageSource);
    }
  }

  function setTemplateShare(templateId: string, sites: SrtSite[] | null) {
    sendAndForget({ type: 'SetTemplateShare', data: { templateId: templateId, sites: sites } } as IInterFrameCom<ITemplateShareParams>, messageSource);
  }

  async function getBuildInfo(templateId: string, templateVersion: number): Promise<SrtGenBuild | null> {
    return sendAndWaitForResponse<IGetBuildInfoParams, SrtGenBuild | null>({ type: 'GetBuildInfo',
      data: { templateVersion: templateVersion, templateId: templateId } } as IInterFrameCom<IGetBuildInfoParams>, messageSource);
  }

  async function buildIds7Package(templateId: string, templateVersion: number, jsCode: string, templateFields: TemplateField[], reportVersion: number,
    templateMetadata: TemplateMetadata): Promise<void> {
    return sendAndWaitForResponse<IBuildIds7PackageParams, void>({ type: 'BuildIds7Package',
      data: { templateId: templateId, templateVersion: templateVersion, jsCode: jsCode, templateFields: templateFields, reportVersion: reportVersion,
        templateMetadata: templateMetadata } } as IInterFrameCom<IBuildIds7PackageParams>, messageSource);
  }

  async function deleteTemplateVersions(templateId: string, deletedVersions: number[]) {
    return sendAndWaitForResponse<IDeleteTemplateVersionsParams, void>({ type: 'DeleteTemplateVersions',
      data: { templateId: templateId, templateVersions: deletedVersions } } as IInterFrameCom<IDeleteTemplateVersionsParams>, messageSource);
  }

  async function getTemplateVersionsById(templateId: string) {
    return sendAndWaitForResponse<string, SrtGenTemplate[]>({ type: 'GetTemplateVersionsById', data: templateId } as IInterFrameCom<string>, messageSource);
  }
  
  async function getAllSites() {
    return sendAndWaitForResponse<void, SrtSite[]>({ type: 'GetAllSites' } as IInterFrameCom<void>, messageSource);
  }

  async function getTemplateShare(templateId: string) {
    return sendAndWaitForResponse<string, number[] | null>({ type: 'GetTemplateShare', data: templateId } as IInterFrameCom<string>, messageSource);
  }

  async function getPreviewEnabled(templateId: string) {
    return sendAndWaitForResponse<string, boolean>({ type: 'GetPreviewEnabled', data: templateId } as IInterFrameCom<string>, messageSource);
  }

  function eventListener(event: MessageEvent) {
    if (messageSource == null && event.source != null) {
      setMessageSource(event.source as WindowProxy);
    }
    let interFrameCom: IInterFrameCom<any> = JSON.parse(event.data);
    switch (interFrameCom.type) {
      case 'SetSuggestedTemplate': {
        const data = interFrameCom.data as ISetSuggestedTemplate;
        setSuggestedTemplateUpdate(data);
        if (data.fromFile) {
          sendAndForget({ type: 'FileLoaded' } as IInterFrameCom<null>, event.source as WindowProxy);
        }
        event.ports[0].postMessage({ type: 'SetSuggestedTemplate' } as IInterFrameCom<void>);
        break;
      }
      case 'SetAppColorMode': {
        const newAppColor = interFrameCom.data as AppColorMode;
        setAppColorMode(newAppColor);
        document.documentElement.className = newAppColor;
        break;
      }
      case 'SetCurrentTemplateFolder':
        setCurrentTemplateFolder(interFrameCom.data as SrtTemplateFolder);
        break;
      case 'SetIsUnsaved':
        setIsUnsaved(interFrameCom.data as boolean);
        break;
      case 'SetProviderNames':
        setProviderNames(interFrameCom.data as string[]);
        break;
      case 'SetProviderData':
        setProviderData(interFrameCom.data as Ids7ProviderData);
        break;
      case 'Initialize': {
        const data: IInitializeParams = interFrameCom.data;
        setAppColorMode(data.appColorMode);
        setCurrentTemplateFolder(data.currentTemplateFolder);
        setIsUnsaved(data.isUnsaved);
        setProviderNames(data.providerNames);
        if (!data.suggestedTemplate.fromFile) {
          setSuggestedTemplateUpdate(data.suggestedTemplate);
        }
        document.documentElement.className = data.appColorMode;
        sendAndForget({ type: 'SetProviderData' } as IInterFrameCom<null>, event.source as WindowProxy);
        setInitialized(true);
        break;
      }
      default:
        applogger.error('Unrecognized interFrameCom type: ', interFrameCom.type);
    }
  }

  const communicationContextValue = useMemo(
    () => ({
      setNewSrtGenTemplateObject: setNewSrtGenTemplateObject,
      setUnsaved: setUnsaved,
      saveChanges: saveChanges,
      isUnsaved: isUnsaved,
      folderId: currentTemplateFolder?.Id ?? 1,
      appColorMode: appColorMode,
      providerNames: providerNames,
      getBuildInfo: getBuildInfo,
      buildIds7Package: buildIds7Package,
      getTemplateVersionsById: getTemplateVersionsById,
      deleteTemplateVersions: deleteTemplateVersions,
      pushHistory: (url: string, options?: any) =>
        sendAndForget({ type: 'PushHistory', data: { url: url, options: options } } as IInterFrameCom<IPushHistoryParams>, messageSource),
      onUpdateProviderData: () => sendAndForget({ type: 'SetProviderData', data: {} } as IInterFrameCom<any>, messageSource),
      setTemplateShare: setTemplateShare,
      getAllSites: getAllSites,
      getTemplateShare: getTemplateShare,
      setPreviewEnabled: (id: string, enabled: boolean) => 
        sendAndForget({ type: 'SetPreviewEnabled', data: { templateId: id, enabled: enabled } } as
        IInterFrameCom<ITemplatePreviewEnabledParams>, messageSource),
      getPreviewEnabled: getPreviewEnabled,
    }),
    [isUnsaved, currentTemplateFolder, appColorMode, providerNames]);

  return (
    <div className="MainContent">
      <SvgSymbol />

      {initialized ? 
      <CommunicationContext.Provider value={communicationContextValue}>
        <TemplateAdmin suggestedTemplate={suggestedTemplateUpdate} />
      </CommunicationContext.Provider>

        : <span>Loading...</span>}
    </div>
  );
};

