/* eslint-disable react/no-children-prop */
import React, { ReactNode } from 'react';
import { SvgRef } from '../Components/Generic/SvgRef';
import { NavLink } from 'react-router-dom';

type NavItemLinkProps = {
  title?: string,
  href: string, 
} & NavItemContentProps;
export function NavItemLink({ icon, name, title, href, children }: NavItemLinkProps) {
  return <li data-highlight-name={name}>
      <NavLink to={href} exact={true} title={title}>
        <NavItemContent icon={icon} name={name} children={children} />
      </NavLink>
    </li>;
}
  
type NavItemButtonProps = {
  title?: string,
  onClick?: (e: React.MouseEvent) => void,
  disabled?: boolean,
} & NavItemContentProps;
export function NavItemButton({ icon, name, title, onClick, disabled, children }: NavItemButtonProps) {
  return <li data-highlight-name={name}>
      <button
        type="button"
        className="clickable"
        onClick={onClick}
        disabled={disabled}
        title={title}
      >
        <NavItemContent icon={icon} name={name} children={children} />
      </button>
    </li>;
}
  
type NavItemContentProps = {
  icon?: string,
  name?: string,
  children?: ReactNode | ReactNode[]
};
export function NavItemContent({ icon, name, children }: NavItemContentProps) {
  return <>
    {icon ? <SvgRef id={icon} /> : null}
    {children ?? <span>{name}</span>}
  </>;
}