/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/rules-of-hooks */
import { applogger } from '../applogger';
import { shallowEqual, useSelector as useReduxSelector } from 'react-redux';
import { AnyAction, configureStore, createAction, createReducer, Draft, getDefaultMiddleware, Store } from '@reduxjs/toolkit';

import { rootElementId } from './SrtTemplateSpec';
import { isDisplayingComponent } from './SrtComponent/SrtComponent';
import { SetterType, StateChangeReason, StateChangeComponentInfo, GetterType, StateChangeReasonType, StateChangeReasonAllType, BasicStateChangeReason, CompositeActionItem, CopyItem, DeleteItem, TemplateContext, ComponentStoreComponentSelectInfo, ComponentStoreState, ComponentStatePropsInfo, ComponentStoreStateComponentInfo, IComponentStateStore, StateChangeSimpleComponentInfo, NewUserInput } from './BasicTypes';


export class ReduxStateStore implements IComponentStateStore {
  private readonly templateContextNotInitialized = 'Cannot run store manipulation without a form context';

  private templateContext: TemplateContext | null = null;

  constructor() {
    this.getStoreValue = this.getStoreValue.bind(this);
  }

  setParentContext(templateContext: TemplateContext) {
    this.templateContext = templateContext;
  }

  useReactState(componentId: string) {
    return useReduxSelector((state: InternalState) => state.componentValues?.[componentId], shallowEqual);
  }

  getState() {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      throw new Error(this.templateContextNotInitialized);
    }

    return getStoreState(actualReduxStore.getState(), this.templateContext);
  }

  replaceState(state: ComponentStoreState) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    actualReduxStore.dispatch(replaceState({ templateContext: this.templateContext, newState: state }));
  }

  clearState(runScripts: boolean) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    actualReduxStore.dispatch(clearComponentData({ templateContext: this.templateContext, runScripts }));
  }
    
  unload() { this.clearState(false); }

  compositeUpdate(operations: CompositeActionItem[], runScripts: boolean) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    actualReduxStore.dispatch(commpositeAction({ templateContext: this.templateContext, items: operations, runScripts: runScripts }));
  }

  setStoreUserInputValue(componentId: string, propName: string, value: any, runScripts: boolean) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    actualReduxStore.dispatch(setUserInput({ templateContext: this.templateContext, componentId, propName, value, runScripts }));
  }
    
  getStoreValue(componentId: string, propName: string | null, getNonDisplaying?: boolean, getLayered?: boolean) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      throw new Error(this.templateContextNotInitialized);
    }

    const templateContext = this.templateContext;
    const state = actualReduxStore.getState().componentValues?.[componentId];
    return generalGetter(templateContext, componentId, propName, getNonDisplaying, getLayered, state, id => isDisplayingComponent(id, this.getStoreValue, templateContext.runtime), undefined);
  }

  copyComponentValues(items: CopyItem[]) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    actualReduxStore.dispatch(copyComponents({ templateContext: this.templateContext, items }));
  }

  deleteComponentValues(items: DeleteItem[]) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    actualReduxStore.dispatch(deleteComponents({ templateContext: this.templateContext, items }));
  }

  runPostProcessingScripts(components: StateChangeSimpleComponentInfo[], operation?: StateChangeReasonAllType) {
    if (this.templateContext == null) {
      applogger.error(this.templateContextNotInitialized);
      return;
    }

    const templateContext = this.templateContext;
    const reason: BasicStateChangeReason = { 
      operation: operation, 
      components: Array.isArray(components) 
        ? components.map(x => getComponentReasonInfo(x.type, x.compId, x.propName, templateContext)) 
        : [], 
    };

    actualReduxStore.dispatch(runCodeExecution({ templateContext, reason: reason }));
  }
}

// Store manipulation actions (internally used)
const commpositeAction = createAction<CompositeUpdateAction>('compositeAction');
const setUserInput = createAction<NewUserInputAction>('setUserInput');
const replaceState = createAction<ReplaceStateAction>('replaceState');
const clearComponentData = createAction<ClearStateAction>('clearComponentData');
const deleteComponents = createAction<DeleteAction>('delete');
const copyComponents = createAction<CopyAction>('copy');
const runCodeExecution = createAction<RunCodeExecutionInfo>('runCodeExecution');

const runCodeExecutionFilter = (action: AnyAction) => (action.payload as BaseUpdateAction)?.runScripts !== false;

const initialState: InternalState = { componentValues: {} };
const reducer = createReducer(initialState, (builder) => {
  // Handle set user input
  builder.addCase(setUserInput, (state, action) => {
    //applogger.debug("setUserInput");
    setInput(state, action.payload);
    action.payload.reason = {
      components: [getComponentReasonInfo('setUserInput', action.payload.componentId, action.payload.propName, action.payload.templateContext)],
    };
  });

  // Handle delete
  builder.addCase(deleteComponents, (state, action)=>{
    // applogger.debug("deleteItem");
    const pl = action.payload;
    deleteIds(state, pl.items, pl.templateContext);
    action.payload.reason = {
      components: pl.items.map(item => getComponentReasonInfo('delete', item.compId, undefined, pl.templateContext)),
    };
  });

  // Handle copy 
  builder.addCase(copyComponents, (state, action) => {
    //applogger.debug("copyItem", action.payload);
    const pl = action.payload;
    copyValues(state, pl.items);
    action.payload.reason =  {
      components: pl.items.map(p => getComponentReasonInfo('copy', p.destCompId, undefined, pl.templateContext)),
    };
  });

  // Handle composite action
  builder.addCase(commpositeAction, (state, action) => {
    const items = action.payload.items;
    const templateContext = action.payload.templateContext;
    const reason: BasicStateChangeReason = action.payload.reason =  {
      components: [],
    };

    items.forEach(item => {
      if (Array.isArray(item) && item.length > 0) {
        let first = (item as any[])[0];
        if ((first as CopyItem).sourceCompId) {
          const cItem  = item as CopyItem[];
          copyValues(state, cItem);
          reason.components.push(...cItem.map(p => getComponentReasonInfo('copy', p.destCompId, undefined, templateContext)));
          return;
        } else if ((first as DeleteItem).compId) {
          const dItem  = item as DeleteItem[];
          deleteIds(state, dItem, templateContext);
          reason.components.push(...dItem.map(i => getComponentReasonInfo('delete', i.compId, undefined, templateContext)));
          return;
        }
      } else if ((item as NewUserInput).componentId) {
        const sItem = item as NewUserInput;
        setInput(state, sItem);
        reason.components.push(getComponentReasonInfo('setUserInput', sItem.componentId, sItem.propName, templateContext));
        return;
      }

      applogger.warn('Unknown composite action item: ', item);
    });
  });

  // Handle clear state
  builder.addCase(clearComponentData, (state, action) => {
    applogger.info('clearing component state');
    state.componentValues = {};
    action.payload.reason = {
      operation: 'clear',
      components: [],
    };
  });

  // Handle replace state
  builder.addCase(replaceState, (state, action) => {
    setStoreState(state, action.payload.newState, action.payload.templateContext);
    action.payload.reason = {
      operation: 'replace',
      components: [],
    };
  });

  builder.addMatcher(runCodeExecutionFilter, (state, action) => {
    const baseAction = (action.payload as BaseAction);
    if (baseAction?.reason != null) {
      calculateValues(state, baseAction.templateContext, baseAction.reason);
    }
  });
});

const actualReduxStore = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
});

export function getReduxStoreForProvider(): Store<any, AnyAction> {
  return actualReduxStore;
}


const setInput = (state: Draft<InternalState>, userInput: NewUserInput) => {
  const compId = userInput.componentId;
  const cInfo = ensureStateIsInitialized(compId, state);
  if (userInput.value !== undefined) {
    cInfo.userInputProps[userInput.propName] = userInput.value ?? null;
  } else {
    delete cInfo.userInputProps[userInput.propName];
  }
};

const deleteIds = (state: Draft<InternalState>, items: DeleteItem[], templateContext: TemplateContext) => {
  items.forEach(item => {
    delete state.componentValues[item.compId];
    templateContext.runtime.unregisterRuntimeComponent(item.compId);
  });
};

const copyValues = (state: Draft<InternalState>, idList: CopyItem[]) => {
  idList.forEach(copy => {
    if (copy.destCompId === copy.sourceCompId) {
      // noting to do
      applogger.warn('copy with the same src and dest, nothing to do...');
      return;
    }

    let sourceInfo = state.componentValues[copy.sourceCompId];
    if (sourceInfo != null) {
      // copy source and update id property (the only thing that's different)
      let destInfo = { ...sourceInfo, componentId: copy.destCompId };
      state.componentValues[copy.destCompId] = destInfo;
      //applogger.debug(`Copy from ${copy.sourceCompId} to ${copy.destCompId}`, destInfo);
    } else {
      // source info doesn't exist, initalize empty at destination
      //applogger.debug(`Missing at ${copy.sourceCompId}, clear into ${copy.destCompId}`);
      delete state.componentValues[copy.destCompId];
    }
  });
};


// redux state objects (internally used)
interface InternalComponentStateInfo extends ComponentStoreComponentSelectInfo {
  componentId: string;
}
interface InternalState {
  componentValues: { [componentId: string]: InternalComponentStateInfo };
}

// store update actions (internally used)
interface BaseAction {
  templateContext: TemplateContext;
  reason?: BasicStateChangeReason;
}
interface BaseUpdateAction extends BaseAction {
  runScripts: boolean;
}

interface CompositeUpdateAction extends BaseUpdateAction {
  items: CompositeActionItem[];
}
interface NewUserInputAction extends BaseUpdateAction, NewUserInput { 
  templateContext: TemplateContext;
}
interface ReplaceStateAction extends BaseAction {
  newState: ComponentStoreState;
}
interface ClearStateAction extends BaseUpdateAction {}
interface DeleteAction extends BaseAction {
  items: DeleteItem[];
}
interface CopyAction extends BaseAction  {
  items: CopyItem[];
}
interface RunCodeExecutionInfo extends BaseAction { 
  reason: BasicStateChangeReason;
}


// The calculator setting up calculated values
let runCounter = 0;
const calculateValues = (state: Draft<InternalState>, templateContext: TemplateContext, basicReason: BasicStateChangeReason): void => {
  const componentValues = state.componentValues;
  const reason: StateChangeReason = {
    changeNumber: ++runCounter,
    components: basicReason.components,
  };
  if (basicReason.operation != null) {
    reason.operation = basicReason.operation;
  }

  applogger.debug('running calculation due to', reason);
  const t0 = performance != null ? performance.now() : null;

  // we're going to write into a new layer of calculated props so that we'll just update
  // the state when needed and not trigger additional unnecessary re-renders (of React-components) 
  const calculatedProps: { [componentId: string]: ComponentStatePropsInfo } = {};

  // special handling for events, if we're fireing an event (i.e. button click) we won't reset the state but rather 
  // just execute the button action
  if (basicReason.components.length === 1 && basicReason.components[0].type === 'event') {
    // we're firing an event, lets initialize the calculated state
    Object.keys(componentValues).forEach(compId => {
      // find all component props
      const calcCompState = componentValues[compId]?.calculatedProps;
      if (calcCompState) {
        const propNames = Object.keys(calcCompState);
        if (propNames.length > 0) {
          calculatedProps[compId] = {};
          propNames.forEach(propName => {
            calculatedProps[compId][propName] = calcCompState[propName];
          });
        }
      }
    });
  }
    
  let iterationStart: string = '';

  // Iterate until stable
  let iteration = 1;
  const maxIterations = 30;
  for (; iteration <= maxIterations; ++iteration) {
    iterationStart = JSON.stringify(calculatedProps);
    
    if (iteration > 20) {
      applogger.error(`processor iteration: ${iteration}`);
    } else if (iteration > 10) {
      applogger.warn(`processor iteration: ${iteration}`);
    }
        
    const getter: GetterType = (componentId, propName, getNonDisplaying, getLayered) => {
      return generalGetter(templateContext, componentId, propName, getNonDisplaying, getLayered, componentValues[componentId], id => isDisplayingComponent(id, getter, templateContext.runtime), calculatedProps);
    };

    const setter: SetterType = (componentId: string, propName: string, value: any, userInput?: boolean | 'force') => {
      if (propName == null) {
        // propName should be specified in the setter
        return;
      }

      // setup default userInput
      userInput = userInput !== true && userInput !== false && userInput !== 'force' ? true : userInput;

      if (calculatedProps[componentId] == null) {
        calculatedProps[componentId] = {};
      }

      if (userInput !== true && userInput !== 'force') {
        if (iteration > 20 && !deepEquals(value, calculatedProps[componentId][propName])) {
          const message = value !== undefined 
            ? `Suspicious set of value: ${value} for ${componentId}.${propName}`
            : `Suspicious delete of ${componentId}.${propName}`;
          applogger.warn(message);
          if (iteration === maxIterations) {
            if (window._addRuntimeError_) {
              window._addRuntimeError_({ message: message, componentId: componentId, propName: propName });
            }
          }
        }
    
        if (value !== undefined) {
          calculatedProps[componentId][propName] = value;
        } else {
          delete calculatedProps[componentId][propName];
        }

        // Also, ensure that this componentId added to componentValues (should probably be there and 
        // ensures that it's covered when updating later on)
        ensureStateIsInitialized(componentId, state);
      } else {
        // set as user input, clear any calculated value before setting user input value
        delete calculatedProps[componentId][propName];

        // skip set of undefined when setting as user input since undefined in the general context wipes the calc-layer 
        // returning to user input, which thus is the expected behaviour. I.e. set of undefined in this context should mean 
        // the same thing and not returning to "YAML default".
        if (value !== undefined || userInput === 'force') {
          setInput(state, { componentId: componentId, propName: propName, value: value });
        }
      }
            
    };

    // Run user defined post processor code
    templateContext.runtime.executeOnStateChangeCode(getter, setter, { iteration: iteration, ...reason });

    if (JSON.stringify(calculatedProps) === iterationStart) {
      break;
    }
  }
  if (iteration >= maxIterations) {
    const message = 'Post processing run-away, most likely due to recurssive unstable formulas... bailing out.';
    applogger.error(message);
    applogger.debug(iterationStart, JSON.stringify(calculatedProps));
    if (window._addRuntimeError_) {
      window._addRuntimeError_({ message: message + ' Consider revising your calculations.' });
    }
  }

  // Update state with new calculatedProps
  Object.keys(componentValues).forEach(compId => {
    // find all component props
    const compState = componentValues[compId];
    const newCalcProps = calculatedProps[compId];
    const propNames = getUniqueKeys(compState.calculatedProps, newCalcProps);
    propNames.forEach(propName => {
      const oldCalcValue = compState.calculatedProps[propName];
      const userInputValue = compState.userInputProps[propName];
      const baseValue = userInputValue !== undefined ? userInputValue : templateContext.runtime.getComponentById(compId)?.args[propName];
      const oldValue = oldCalcValue !== undefined ? oldCalcValue : baseValue;

      const newCalcValue = newCalcProps?.[propName];
      const newValue = newCalcValue !== undefined ? newCalcValue : baseValue;

      // test if update is needed (we don't want to fiddle with the State Object if we don't have to)
      if (!deepEquals(newValue, oldValue)) {
        //applogger.debug(`updating ${compId}.${propName} to ${newValue} (previously: ${oldValue})`);
        if (newCalcValue !== undefined && !deepEquals(newCalcValue, userInputValue)) {
          compState.calculatedProps[propName] = newCalcValue;
        } else {
          delete compState.calculatedProps[propName];
        }
      }
    });
  });

  if (t0 != null) {
    applogger.debug(`${performance.now() - t0} - ms elapsed running the calculation of values`);
  }
};

const getStoreState = (state: InternalState, templateContext: TemplateContext): ComponentStoreState => {
  const result: ComponentStoreState = {};
  Object.keys(state.componentValues).forEach(compId => {
    const cInfo = state.componentValues[compId];
    const userData = cInfo.userInputProps != null && Object.keys(cInfo.userInputProps).length > 0 ? { ...cInfo.userInputProps } : null;
    const scriptData = cInfo.calculatedProps != null && Object.keys(cInfo.calculatedProps).length > 0 ? { ...cInfo.calculatedProps } : null;
    const runtimeInfo = templateContext.runtime.getRuntimeInfoById(compId);        
    if (runtimeInfo != null && (userData != null || scriptData != null)) {
      const compState: ComponentStoreStateComponentInfo = {};

      if (runtimeInfo.componentSourceId != compId) {
        compState.c = runtimeInfo.componentSourceId;
      }
      if (runtimeInfo.componentParentId != null && runtimeInfo.componentParentId !== rootElementId) {
        compState.p = runtimeInfo.componentParentId;
      }

      if (userData != null) {
        compState.user = userData;
      }
      if (scriptData != null) {
        compState.script = scriptData;
      }
      result[compId] = compState;
    }
  });
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updatePropsInfo = (oldPropsInfo: ComponentStatePropsInfo, newPropsInfo: ComponentStatePropsInfo | undefined | null, context: string) => {
  const oldPropNames = Object.keys(oldPropsInfo);
  if (newPropsInfo == null) {
    if (oldPropNames.length === 0) {
      return;
    }

    //applogger.debug(context + " - Empty props object recieved, clearing all existing props: " + oldPropNames);
    oldPropNames.forEach(propName => {
      delete oldPropsInfo[propName];
    });
    return;
  }

  const newPropNames = Object.keys(newPropsInfo);
  const newPropNameLookup: { [propName: string] : boolean } = {};
  newPropNames.forEach(propName => newPropNameLookup[propName] = true);

  // drop all comps existing in the current state not existing in the new state
  oldPropNames
    .filter(propName => newPropNameLookup[propName] !== true)
    .forEach(propName => {
      //applogger.debug(context + ":" + propName + " - value missing in new scope - deleting property");
      delete oldPropsInfo[propName];
    });

  // update
  newPropNames.forEach(propName => {
    if (!deepEquals(oldPropsInfo[propName], newPropsInfo[propName])) {
      //applogger.debug(context + ":" + propName + " - new value - " + (oldPropsInfo[propName] != null ? "updating property" : "adding property"));
      oldPropsInfo[propName] = newPropsInfo[propName];
    }
  });
};

const setStoreState = (state: InternalState, storeState: ComponentStoreState | null, templateContext: TemplateContext): void => {
  if (storeState == null) {
    state.componentValues = {};
    applogger.warn('An empty state received');
    return;
  }
    
  const componentIds = Object.keys(storeState);
  const compIdLookup: { [compId: string] : boolean } = {};
  componentIds.forEach(compId => compIdLookup[compId] = true);

  // drop all comps existing in the current state not existing in the new state
  Object.keys(state.componentValues)
    .filter(compId => compIdLookup[compId] !== true)
    .forEach(compId => {
      //applogger.debug(compId + " - missing in new scope - deleting component");
      delete state.componentValues[compId]; 
    });


  // update
  componentIds.forEach(compId => {
    const newCompState = storeState[compId];
    let cInfo = state.componentValues[compId];
    if (cInfo == null) {
      //applogger.debug(compId + " - missing in existing scope - adding component");
      cInfo = state.componentValues[compId] = getEmptyCompInfo(compId);
    }

    // register component info
    templateContext.runtime.registerRuntimeComponentInfo(compId, newCompState.c ?? compId, newCompState.p ?? rootElementId);

    // updating user props
    updatePropsInfo(cInfo.userInputProps, newCompState.user, compId + ':user');
    updatePropsInfo(cInfo.calculatedProps, newCompState.script, compId + ':script');
  });
  templateContext.setCustomStateExecuted = true;
};

function generalGetter(templateContext: TemplateContext, componentId: string, propName: string | null | undefined, getNonDisplaying: boolean | null | undefined, getLayeredData: boolean | null | undefined,
  ci: InternalComponentStateInfo | null | undefined, testDisplaying: (compId: string) => boolean, customCalcProps?: { [componentId: string]: ComponentStatePropsInfo }): any {
  if (componentId == null) return undefined;
  const componentCalcProps = customCalcProps !== undefined ? customCalcProps[componentId] : ci?.calculatedProps;

  if (propName === null) {
    const componentArgs = templateContext.runtime.getComponentById(componentId)?.args;

    // get with propName activly set to null yeilds all components values
    return {
      ...componentArgs,                       // layer 0
      ...ci?.userInputProps,                  // layer 1
      ...componentCalcProps, id: componentId,  // layer 2
    };                 
  }

  if (propName == null) propName = 'value'; // default to value if not supplied
  if (getNonDisplaying === false && !testDisplaying(componentId)) {
    return undefined;
  }

  const calculatedValue = componentCalcProps?.[propName];

  const componentArgs = templateContext.runtime.getComponentById(componentId)?.args[propName];
  if (getLayeredData === true) return [
    calculatedValue,                    // layer 2
    ci?.userInputProps?.[propName],     // layer 1
    componentArgs,                       // layer 0
  ];

  if (calculatedValue !== undefined) return calculatedValue;  // layer 2
  const value = ci?.userInputProps?.[propName];               // layer 1
  if (value !== undefined) return value;
  return componentArgs;                                       // layer 0
}

function getComponentReasonInfo(type: StateChangeReasonType, compId: string, propName: string | null | undefined, templateContext: TemplateContext): StateChangeComponentInfo {
  let cri = templateContext.runtime.getComponentById(compId);
  return {
    type: type,
    name: compId + (propName != null ? '.' + propName : ''),
    componentId: cri?.id,
    prefix: cri != null && cri.id != null
      ? cri.id.length < compId.length ? compId.substr(0, compId.length - cri.id.length) : ''
      : undefined,
    propName: propName != null ? propName : undefined,
  };
}

//
// Helper functions
//

function getUniqueKeys(o1: any, o2: any): string[] {
  return arrayUnion(o1 != null ? Object.keys(o1) : null, o2 != null ? Object.keys(o2) : null);
}

function arrayUnion<T>(arr1: T[] | null | undefined, arr2: T[] | null | undefined): T[] {
  if (arr1 != null && arr2 != null) {
    return arr1.concat(arr2).filter((val: T, idx: number, arr: T[]) => arr.indexOf(val) === idx);
  }
  return arr1 != null ? arr1 : (arr2 != null ? arr2 : []);
}

function deepEquals(oldValue: any, newValue: any): boolean {
  if (newValue != null && oldValue != null && Array.isArray(newValue) && Array.isArray(oldValue)) {
    // Do array comparison
    if (newValue.length !== oldValue.length) {
      return false;
    }

    return JSON.stringify(oldValue) === JSON.stringify(newValue);
  } else if (newValue != null && oldValue != null && typeof newValue === 'object' &&  typeof oldValue === 'object') {
    // Do object comparison
    return JSON.stringify(oldValue) === JSON.stringify(newValue);
  } else {
    return oldValue === newValue || numberEquals(oldValue, newValue);
  }
}

function numberEquals(oldValue: any, newValue: any): boolean {
  if (oldValue == null || newValue == null || typeof oldValue !== 'number' || typeof newValue !== 'number') {
    return false;
  }
  return oldValue === newValue || (isNaN(oldValue) && isNaN(newValue));
}

function getEmptyCompInfo(componentId: string): InternalComponentStateInfo {
  return {
    componentId: componentId,
    userInputProps: {},
    calculatedProps: {},
  };
}

function ensureStateIsInitialized(componentId: string, state: InternalState): InternalComponentStateInfo {
  let cInfo = state.componentValues[componentId];
  if (cInfo != null) {
    return cInfo;
  }

  // create empty objects if no current data
  return state.componentValues[componentId] = getEmptyCompInfo(componentId);
}